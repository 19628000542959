<template>
  <div class="py-10 px-16">
    <p class="font-bold text-xl mb-5">
      {{ $t('settings.manageProfile.title') }}
    </p>
    <div v-if="issuerRetrieved" class="pt-10">
      <div class="flex gap-20 mb-10">
        <div class="flex-none">
          <p class="font-bold text-md mb-3">Profile Image</p>
          <div class="mb-7 flex items-center gap-3">
            <div
              class="relative"
              @mouseenter="showPictureButton = true"
              @mouseleave="showPictureButton = false"
            >
              <img
                :src="issuerPicturePreview"
                alt="profile_picture"
                class="w-32 h-32 bg-gray-200 rounded-full"
              />
              <div v-if="showPictureButton" class="absolute bottom-0 left-0">
                <div
                  class="w-10 h-10 flex items-center justify-center text-xl bg-white border shadow-md font-medium rounded-lg cursor-pointer"
                  @click="showImageHandler = true"
                >
                  <icon name="image" />
                </div>
              </div>
            </div>
            <p v-if="issuerPictureMessage" class="text-xs text-red-600">
              {{ issuerPictureMessage }}
            </p>
          </div>
        </div>
        <!-- <div>
          <p class="font-bold text-md mb-3">Profile Banner</p>
          <div class="w-96 mb-7 flex items-center gap-3">
            <div
              class="relative"
              @mouseenter="showCoverButton = true"
              @mouseleave="showCoverButton = false"
            >
              <img
                v-if="issuerCoverPreview"
                :src="issuerCoverPreview"
                alt="profile_picture"
                class="h-32 aspect-[3/1] bg-gray-200 rounded-md"
              />
              <div
                v-else
                class="h-32 aspect-[3/1] bg-gray-200 rounded-md"
              ></div>
              <div v-if="showCoverButton" class="absolute bottom-0 left-0">
                <div
                  class="w-10 h-10 flex items-center justify-center text-xl bg-white border shadow-md font-medium rounded-lg cursor-pointer"
                  @click="showCoverHandler = true"
                >
                  <icon name="image" />
                </div>
              </div>
            </div>
            <p v-if="issuerPictureMessage" class="text-xs text-red-600">
              {{ issuerPictureMessage }}
            </p>
          </div>
        </div>
        <div>
          <p class="font-bold text-md mb-3">Tags</p>
          <div class="w-72" click-outside-ignore-tagpicker>
            <input
              type="text"
              :placeholder="'Enter topic'"
              class="w-full outline-none border-gray-300 border rounded-lg px-3 py-2"
              v-model="tagQuery"
              @input="showTagPicker = true"
            />
          </div>
          <div
            v-if="tagList.length > 0"
            class="flex flex-wrap w-72 mt-1 gap-0.5"
            click-outside-ignore-tagpicker
          >
            <span
              v-for="tag in tagList"
              :key="tag"
              class="flex-none text-xs bg-gray-200 font-bold rounded-lg py-1 px-2"
              >{{ tag
              }}<icon
                @click="
                  () => {
                    tagList.splice(tagList.indexOf(tag), 1);
                  }
                "
                name="cross"
                class="fill-gray-500 w-3 h-3 pb-0.5 ml-0.5 cursor-pointer"
            /></span>
          </div>
          <div
            v-if="
              showTagPicker &&
              tagAutoComplete &&
              tagAutoComplete.filter((tag) => !tagList.includes(tag)).length > 0
            "
            class="relative"
            v-click-outside="
              () => {
                showTagPicker = false;
              }
            "
            click-outside-ignore-tagpicker-target
          >
            <div
              class="absolute w-72 top-1 bg-white px-3 py-2 border rounded-xl shadow-md max-h-80 overflow-y-auto z-20"
            >
              <p
                v-for="tag in tagAutoComplete.filter(
                  (tag) => !tagList.includes(tag)
                )"
                :key="tag"
                @click="
                  () => {
                    tagList.push(tag);
                  }
                "
                class="text-sm font-medium my-1 hover:bg-gray-light rounded-md cursor-pointer"
              >
                {{ tag }}
              </p>
            </div>
          </div>
        </div> -->
      </div>
      <div class="flex gap-20 mb-10">
        <div>
          <p class="font-bold text-md mb-3">Information</p>
          <div class="w-96 mb-3">
            <span
              class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3"
              >{{ $t('settings.manageProfile.namePlaceholder') }}</span
            >
            <input
              type="text"
              :placeholder="$t('settings.manageProfile.namePlaceholderBis')"
              class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
              v-model="issuerName"
            />
          </div>
          <div class="w-96 mb-1.5">
            <span
              class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3"
              >{{ $t('settings.manageProfile.descriptionPlaceholder') }}</span
            >
            <textarea
              type="text"
              :placeholder="
                $t('settings.manageProfile.descriptionPlaceholderBis')
              "
              class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
              rows="4"
              v-model="issuerDescription"
            />
          </div>
        </div>
        <div>
          <p class="font-bold text-md mb-3">Links</p>
          <div class="w-96 mb-3">
            <span
              class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3"
              >{{ $t('settings.manageProfile.discordPlaceholder') }}</span
            >
            <input
              type="text"
              :placeholder="$t('settings.manageProfile.discordPlaceholder')"
              class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
              v-model="issuerDiscord"
            />
          </div>
          <div class="w-96 mb-3">
            <span
              class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3"
              >{{ $t('settings.manageProfile.twitterPlaceholder') }}</span
            >
            <input
              type="text"
              :placeholder="$t('settings.manageProfile.twitterPlaceholder')"
              class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
              v-model="issuerTwitter"
            />
          </div>
          <div class="w-96 mb-5">
            <span
              class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3"
              >{{ $t('settings.manageProfile.websitePlaceholder') }}</span
            >
            <input
              type="text"
              :placeholder="$t('settings.manageProfile.websitePlaceholder')"
              class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
              v-model="issuerWebsite"
            />
          </div>
        </div>
      </div>

      <div class="flex gap-5 justify-end">
        <button
          @click="validateProfile() && (waitingProfileConfirmation = true)"
          class="bg-secondary text-white shadow-sm font-medium rounded-lg px-3 py-2 cursor-pointer"
        >
          {{ $t('settings.manageProfile.saveProfileBtn') }}
        </button>
      </div>
    </div>
    <div v-if="waitingSignature">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-app.jpg')"
            :height="160"
            :width="160"
            :speed="0.7"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-1">
          {{ $t('other.confirmProfileUpdate') }}
        </p>
        <p class="text-center px-10 mb-4">
          {{ $t('templateCreation.onMydidAppTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="waitingProfileConfirmation">
      <div class="fixed top-0 left-0 w-full h-full z-10">
        <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
      </div>
      <BasicSmModal @close="waitingProfileConfirmation = false">
        <p class="text-center text-xl font-bold px-5 mb-10">
          {{ $t('settings.manageProfile.updateConfirmTxt') }}
        </p>
        <div class="flex justify-center">
          <button
            @click="updateProfile() && (waitingProfileConfirmation = false)"
            class="bg-secondary text-white shadow-sm font-medium rounded-lg px-3 py-2 cursor-pointer"
          >
            {{ $t('settings.manageProfile.updateConfirmButton') }}
          </button>
        </div>
      </BasicSmModal>
    </div>
    <ImageHandler
      v-if="showImageHandler"
      :noGallery="true"
      @select="
        (file, data) => {
          issuerPicturePreview = data;
          issuerPictureFile = file;
          showImageHandler = false;
        }
      "
      @close="showImageHandler = false"
    ></ImageHandler>
    <ImageHandler
      v-if="showCoverHandler"
      :noGallery="true"
      :aspectRatio="3"
      @select="
        (file, data) => {
          issuerCoverPreview = data;
          issuerCoverFile = file;
          showCoverHandler = false;
        }
      "
      @close="showCoverHandler = false"
    ></ImageHandler>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import BasicSmModal from '../../components/BasicSmModal.vue';
import ImageHandler from '../../components/ImageHandler.vue';
import { watch } from 'vue';
import topics from '../../../assets/interests.json';

export default {
  setup() {
    const store = useStore();
    const utils = inject('utils');
    const api = inject('api');
    const { t } = useI18n();

    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const waitingProfileConfirmation = ref(false);
    const waitingSignature = ref(false);
    const issuerRetrieved = ref(false);
    const showImageHandler = ref(false);
    const showCoverHandler = ref(false);
    const showPictureButton = ref(false);
    const showCoverButton = ref(false);
    const showTagPicker = ref(false);

    const assertionKey = ref(null);

    const issuerPictureFile = ref(null);
    const issuerPictureMessage = ref(null);
    const issuerPicturePreview = ref(null);
    const issuerCoverFile = ref(null);
    const issuerCoverMessage = ref(null);
    const issuerCoverPreview = ref(null);

    const issuerName = ref(null);
    const issuerSector = ref(null);
    const issuerDescription = ref(null);
    const issuerWebsite = ref(null);
    const issuerTwitter = ref(null);
    const issuerDiscord = ref(null);

    const tagList = ref([]);
    const tagQuery = ref('');
    const tagAutoComplete = computed(() => {
      if (!tagQuery.value || tagQuery.value.length < 2) {
        return null;
      }
      return topics.filter((topic) =>
        topic.toLowerCase().includes(tagQuery.value.toLowerCase())
      );
    });

    function initIssuerProfile() {
      issuerName.value = currentIssuerInfo.value.name;
      issuerSector.value = currentIssuerInfo.value.sector;
      issuerDescription.value = currentIssuerInfo.value.description;
      issuerWebsite.value = currentIssuerInfo.value.website;
      issuerTwitter.value = currentIssuerInfo.value.twitter;
      issuerDiscord.value = currentIssuerInfo.value.discord;

      utils
        .getBase64FromImageUrl(currentIssuerInfo.value.profileImage)
        .then((data) => {
          issuerPicturePreview.value = data;
          issuerPictureFile.value = utils.base64ToFile(data);
          issuerRetrieved.value = true;
        });
    }

    if (currentIssuerInfo.value) initIssuerProfile();
    watch(currentIssuerInfo, () => {
      if (currentIssuerInfo.value && !issuerRetrieved.value)
        initIssuerProfile();
    });

    function validateProfile() {
      try {
        checkProfile();
        return true;
      } catch (err) {
        console.log(err);
        utils.showToastMessageError(err);
        return false;
      }
    }

    function checkProfile() {
      if (!issuerPictureFile.value) throw t('signup.errors.pictureMissing');
      // if (!issuerCoverFile.value) throw t('signup.errors.pictureMissing');

      if (!issuerName.value) throw t('signup.errors.nameMissing');
      if (issuerName.value.length > 100) throw t('signup.errors.nameTooLong');
      const issuerNameRegex =
        /[mM](\.| |-|_)?([yY](\.| |-|_)?)([dD](\.| |-|_)?)([iI](\.| |-|_)?)[dD]/;
      if (issuerNameRegex.test(issuerName.value))
        throw t('signup.errors.nameInvalid');

      if (!issuerSector.value) throw t('signup.errors.sectorMissing');
      if (issuerSector.value.length > 100)
        throw t('signup.errors.sectorTooLong');

      if (!issuerDescription.value) throw t('signup.errors.descriptionMissing');
      if (issuerDescription.value.length > 1000)
        throw t('signup.errors.descriptionTooLong');

      const discordRegex =
        /^(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+$/;
      if (issuerDiscord.value && !discordRegex.test(issuerDiscord.value))
        throw t('signup.errors.discordInvalid');

      const twitterRegex =
        /^(https?:\/\/)?(twitter|x).com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+)$/;
      if (issuerTwitter.value && !twitterRegex.test(issuerTwitter.value))
        throw t('signup.errors.twitterInvalid');

      const websiteRegex =
        /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
      if (issuerWebsite.value && !websiteRegex.test(issuerWebsite.value))
        throw t('signup.errors.websiteInvalid');

      return true;
    }

    async function updateProfile() {
      waitingSignature.value = 'updateProfile';
      api
        .updateIssuer(
          {
            name: issuerName.value,
            description: issuerDescription.value,
            sector: issuerSector.value,
            website: issuerWebsite.value,
            twitter: issuerTwitter.value,
            discord: issuerDiscord.value,
          },
          issuerPictureFile.value
        )
        .then((res) => {
          const { callbackId } = res.data;

          api.waitOperationValidation(callbackId, (event) => {
            if (event.status == 'canceled') {
              waitingSignature.value = null;
            } else if (event.status == 'pending') {
              store.dispatch('getIssuerInfo');
              waitingSignature.value = null;
            } else if (event.status == 'error') {
              waitingSignature.value = null;
              utils.showToastMessageError(t('other.serverError'));
            }
          });
        })
        .catch(() => {
          utils.showToastMessageError(t('other.serverError'));
          waitingSignature.value = null;
        });
    }

    return {
      waitingSignature,
      waitingProfileConfirmation,
      showImageHandler,
      showCoverHandler,
      showPictureButton,
      showCoverButton,
      showTagPicker,
      currentIssuerInfo,
      assertionKey,
      updateProfile,
      validateProfile,
      issuerRetrieved,
      issuerPictureFile,
      issuerPictureMessage,
      issuerPicturePreview,
      issuerCoverFile,
      issuerCoverMessage,
      issuerCoverPreview,
      issuerName,
      issuerSector,
      issuerDescription,
      issuerWebsite,
      issuerTwitter,
      issuerDiscord,
      tagList,
      tagQuery,
      tagAutoComplete,
    };
  },
  components: {
    BasicSmModal,
    ImageHandler,
  },
};
</script>
