<template>
  <div class="py-10 px-16">
    <p class="font-bold text-xl mb-10">Manage quests</p>
    <p class="text-lg mb-3">Challenges</p>
    <div class="flex flex-wrap gap-2 mb-5">
      <div
        v-for="challenge in challenges"
        :key="challenge.id"
        class="flex-none w-64 border border-gray-600 bg-gray-100 rounded-lg p-3"
      >
        <div class="flex items-center gap-2">
          <p class="font-bold text-sm">{{ challenge.type }}</p>
          <div v-if="challenge.type == 'vcAuth'">
            <p class="text-sm">{{ challenge.data.vcKey }}</p>
          </div>
          <div v-if="challenge.type == 'xPost'">
            <p class="text-sm">{{ challenge.data.message }}</p>
          </div>
          <div v-if="challenge.type == 'xFollow'">
            <p class="text-sm">{{ challenge.data.targetAccount }}</p>
          </div>
          <div v-if="challenge.type == 'xRepost'">
            <p class="text-sm">{{ challenge.data.tweetId }}</p>
          </div>
        </div>
        <p class="text-xs">
          {{
            challenge.created.replace('T', ' ').replace('Z', '').split('.')[0]
          }}
        </p>
        <p
          @click="copyToClipboard(challenge.id)"
          class="text-xxs cursor-pointer"
        >
          {{ challenge.id }}<icon name="copy" class="ml-1" />
        </p>
      </div>
    </div>
    <div class="flex gap-2">
      <select
        class="w-[18rem] border-gray-200 border-[1px] focus:border-gray-300 focus:ring-0 placeholder:text-gray-900 placeholder:text-sm hover:bg-gray-light cursor-pointer rounded-md py-1 px-2 mb-1.5 mr-5"
        v-model="challengeType"
      >
        <option value="vcAuth">VC Auth</option>
        <option value="xAuth">X Auth</option>
        <option value="xPost">X Post</option>
        <option value="xFollow">X Follow</option>
        <option value="xRepost">X Repost</option>
      </select>
      <div v-if="challengeType == 'vcAuth'" class="flex gap-2">
        <p class="pt-1">VC key :</p>
        <select
          class="w-[18rem] border-gray-200 border-[1px] focus:border-gray-300 focus:ring-0 placeholder:text-gray-900 placeholder:text-sm hover:bg-gray-light cursor-pointer rounded-md py-1 px-2 mb-1.5 mr-5"
          v-model="challengeData"
        >
          <option value="pseudo">Pseudo</option>
          <option value="walletAddress">Wallet Address</option>
          <option value="publicKey">Public Key</option>
          <option value="did">DID</option>
          <option value="authenticationKey">Authentication Key</option>
          <option value="email">Email</option>
          <option value="telephone">Telephone</option>
          <option value="xUsername">X Username</option>
        </select>
      </div>
      <div v-if="challengeType == 'xPost'" class="flex gap-2">
        <p class="pt-1">Message :</p>
        <input
          class="w-[18rem] border-gray-200 border-[1px] focus:border-gray-300 focus:ring-0 placeholder:text-gray-900 placeholder:text-sm hover:bg-gray-light cursor-pointer rounded-md py-1 px-2 mb-1.5"
          :placeholder="'Enter message'"
          maxlength="100"
          v-model="challengeData"
        />
      </div>
      <div v-if="challengeType == 'xFollow'" class="flex gap-2">
        <p class="pt-1">Targeted X Account :</p>
        <input
          class="w-[18rem] border-gray-200 border-[1px] focus:border-gray-300 focus:ring-0 placeholder:text-gray-900 placeholder:text-sm hover:bg-gray-light cursor-pointer rounded-md py-1 px-2 mb-1.5"
          :placeholder="'Enter X Account'"
          maxlength="100"
          v-model="challengeData"
        />
      </div>
      <div v-if="challengeType == 'xRepost'" class="flex gap-2">
        <p class="pt-1">Tweet ID :</p>
        <input
          class="w-[18rem] border-gray-200 border-[1px] focus:border-gray-300 focus:ring-0 placeholder:text-gray-900 placeholder:text-sm hover:bg-gray-light cursor-pointer rounded-md py-1 px-2 mb-1.5"
          :placeholder="'Enter tweet ID'"
          maxlength="100"
          v-model="challengeData"
        />
      </div>
    </div>
    <button
      class="text-md bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2"
      @click="createChallenge"
    >
      Create challenge
    </button>
    <p class="text-lg mb-2 mt-16">Quests</p>
    <div class="flex flex-wrap gap-2 mb-5">
      <div
        v-for="quest in quests"
        :key="quest.id"
        class="flex-none w-full border border-gray-600 bg-gray-100 rounded-lg p-3"
      >
        <div class="flex">
          <p class="font-bold mr-2">{{ quest.title }}</p>
          <p
            class="text-sm border border-black rounded-md px-1 cursor-pointer"
            @click="fillQuestFields(quest)"
          >
            Edit<icon name="edit-pencil" class="text-xs ml-1 pb-0.5" />
          </p>
        </div>
        <p class="text-sm mb-2">Description : {{ quest.description }}</p>
        <p class="text-xs">Challenges : {{ quest.challenges.join(', ') }}</p>
        <p class="text-xs mb-2">
          Required challenges : {{ quest.requiredChallenges.join(', ') }}
        </p>
        <p class="text-xs">
          Start date :
          {{ quest.startDate.replace('T', ' ').replace('Z', '').split('.')[0] }}
        </p>
        <p class="text-xs mb-2">
          End date :
          {{ quest.endDate.replace('T', ' ').replace('Z', '').split('.')[0] }}
        </p>
        <p class="text-xs">
          Terms URL :
          <a :href="quest.termsUrl" target="_blank">{{ quest.termsUrl }}</a>
        </p>
        <p class="text-xs">Success message : {{ quest.successMessage }}</p>
        <p class="text-xs">
          Limit : {{ quest.limit ? quest.limit : 'No limit' }}
        </p>
      </div>
    </div>
    <p v-if="questId" class="mb-2">
      Editing quest
      <span class="font-bold">{{
        quests.find((quest) => quest.id == questId).title
      }}</span>
    </p>
    <div class="flex gap-2">
      <div class="w-96 mb-3">
        <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3"
          >Title</span
        >
        <input
          type="text"
          :placeholder="'Enter title'"
          class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
          v-model="questTitle"
        />
      </div>
      <div class="w-96 mb-3">
        <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3"
          >Description</span
        >
        <input
          type="text"
          :placeholder="'Enter description'"
          class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
          v-model="questDescription"
        />
      </div>
    </div>
    <div class="w-[775px] mb-3">
      <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3"
        >Challenges IDs (separated by commas)</span
      >
      <input
        type="text"
        :placeholder="'Enter challenges IDs'"
        class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
        v-model="questChallenges"
      />
    </div>
    <div class="w-[775px] mb-3">
      <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3"
        >Required challenges IDs (separated by commas)</span
      >
      <input
        type="text"
        :placeholder="'Enter required challenges IDs'"
        class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
        v-model="questRequiredChallenges"
      />
    </div>
    <div class="flex gap-2">
      <div class="w-64 mb-3">
        <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3"
          >Terms URL</span
        >
        <input
          type="text"
          :placeholder="'Enter terms URL'"
          class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
          v-model="questTermsURL"
        />
      </div>
      <div class="w-64 mb-3">
        <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3"
          >Success message</span
        >
        <input
          type="text"
          :placeholder="'Enter success message'"
          class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
          v-model="questSuccessMessage"
        />
      </div>
      <div class="w-64 mb-3">
        <span class="absolute text-xs font-bold text-gray-400 pt-1.5 pl-3"
          >Limit</span
        >
        <input
          type="number"
          :placeholder="'Enter limit'"
          class="w-full outline-none border-gray-300 border rounded-lg px-3 pb-2 pt-5"
          v-model="questLimit"
        />
      </div>
    </div>
    <div class="flex flex-row items-center">
      <p class="w-32">
        {{ $t('templateCreation.startDateTxt') }}
      </p>
      <div class="relative">
        <p
          @click="showQuestStartDatePicker = !showQuestStartDatePicker"
          class="w-32 h-7 border border-gray-300 outline text-sm outline-0 hover:bg-gray-light cursor-pointer rounded-md pt-0.5 px-1"
          click-outside-ignore-startdatepicker
        >
          {{ questStartDate.toISOString().split('T')[0] }}
        </p>
        <div
          v-if="showQuestStartDatePicker"
          class="absolute w-modal-xs bg-white border shadow-md rounded-md z-10"
        >
          <div
            class="h-fit"
            click-outside-ignore-startdatepicker-target
            v-click-outside="
              () => {
                showQuestStartDatePicker = false;
              }
            "
          >
            <datepicker
              class="bg-gray-200 border-none"
              :inline="true"
              menu-class-name="w-modal-xs"
              v-model="startDatePickerValue"
              :preview-format="formatDateWithLocation"
              @update:model-value="
                () => {
                  questStartDate = startDatePickerValue;
                  showQuestStartDatePicker = false;
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row items-center">
      <p class="w-32">
        {{ $t('templateCreation.endDateTxt') }}
      </p>
      <div class="relative">
        <p
          @click="showQuestEndDatePicker = !showQuestEndDatePicker"
          class="w-32 h-7 border border-gray-300 outline text-sm outline-0 hover:bg-gray-light cursor-pointer rounded-md pt-0.5 px-1"
          click-outside-ignore-enddatepicker
        >
          {{ questEndDate.toISOString().split('T')[0] }}
        </p>
        <div
          v-if="showQuestEndDatePicker"
          class="absolute w-modal-xs bg-white border shadow-md rounded-md z-10"
        >
          <div
            class="h-fit"
            click-outside-ignore-enddatepicker-target
            v-click-outside="
              () => {
                showQuestEndDatePicker = false;
              }
            "
          >
            <datepicker
              class="bg-gray-200 border-none"
              :inline="true"
              menu-class-name="w-modal-xs"
              v-model="endDatePickerValue"
              :preview-format="formatDateWithLocation"
              @update:model-value="
                () => {
                  questEndDate = endDatePickerValue;
                  showQuestEndDatePicker = false;
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
    <button
      class="text-md bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2 mt-5"
      @click="questId ? updateQuest() : createQuest()"
    >
      {{ questId ? 'Update quest' : 'Create quest' }}
    </button>
  </div>
</template>

<script>
import { ref, watch, onMounted, inject, computed } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const { t } = useI18n();

    const challenges = ref([]);
    const challengeType = ref('vcAuth');
    const challengeData = ref('');
    const quests = ref([]);
    const questStartDate = ref(new Date());
    const questEndDate = ref(new Date());
    const showQuestStartDatePicker = ref(false);
    const showQuestEndDatePicker = ref(false);
    const questTitle = ref('');
    const questDescription = ref('');
    const questChallenges = ref('');
    const questRequiredChallenges = ref('');
    const questTermsURL = ref('');
    const questSuccessMessage = ref('');
    const questLimit = ref(null);
    const questId = ref(null);

    const computedChallengeData = computed(() => {
      if (challengeType.value == 'vcAuth') {
        return { vcKey: challengeData.value };
      } else if (challengeType.value == 'xAuth') {
        return null;
      } else if (challengeType.value == 'xPost') {
        return { message: challengeData.value };
      } else if (challengeType.value == 'xFollow') {
        return { targetAccount: challengeData.value };
      } else if (challengeType.value == 'xRepost') {
        return { tweetId: challengeData.value };
      }
    });

    function emptyChallengeFields() {
      challengeType.value = 'vcAuth';
      challengeData.value = '';
    }

    function emptyQuestFields() {
      questTitle.value = '';
      questDescription.value = '';
      questChallenges.value = '';
      questRequiredChallenges.value = '';
      questTermsURL.value = '';
      questSuccessMessage.value = '';
      questLimit.value = null;
      questId.value = null;
      questStartDate.value = new Date();
      questEndDate.value = new Date();
    }

    function fillQuestFields(quest) {
      questId.value = quest.id;
      questTitle.value = quest.title;
      questDescription.value = quest.description;
      questChallenges.value = quest.challenges.join(',');
      questRequiredChallenges.value = quest.requiredChallenges.join(',');
      questTermsURL.value = quest.termsUrl;
      questSuccessMessage.value = quest.successMessage;
      questLimit.value = quest.limit;
      questStartDate.value = new Date(quest.startDate);
      questEndDate.value = new Date(quest.endDate);
    }

    async function getChallenges() {
      api.getCommunityChallenges().then((response) => {
        challenges.value = response.data;
      });
    }
    getChallenges();

    async function getQuests() {
      api.getCommunityQuests().then((response) => {
        quests.value = response.data;
      });
    }
    getQuests();

    async function createChallenge() {
      api
        .createCommunityChallenge({
          type: challengeType.value,
          data: computedChallengeData.value,
        })
        .then(() => {
          getChallenges();
          emptyChallengeFields();
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response?.data?.message) {
            utils.showToastMessageError(err.response?.data?.message);
          } else {
            utils.showToastMessageError(t('other.serverError'));
          }
        });
    }

    async function createQuest() {
      api
        .createCommunityQuest({
          title: questTitle.value,
          description: questDescription.value,
          challenges: questChallenges.value.trim().split(','),
          requiredChallenges: questRequiredChallenges.value.trim().split(','),
          startDate: questStartDate.value,
          endDate: questEndDate.value,
          termsUrl: questTermsURL.value,
          successMessage: questSuccessMessage.value,
          limit: questLimit.value,
        })
        .then(() => {
          getQuests();
          emptyQuestFields();
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response?.data?.message) {
            utils.showToastMessageError(err.response?.data?.message);
          } else {
            utils.showToastMessageError(t('other.serverError'));
          }
        });
    }

    async function updateQuest() {
      api
        .updateCommunityQuest(questId.value, {
          title: questTitle.value,
          description: questDescription.value,
          challenges: questChallenges.value.trim().split(','),
          requiredChallenges: questRequiredChallenges.value.trim().split(','),
          startDate: questStartDate.value,
          endDate: questEndDate.value,
          termsUrl: questTermsURL.value,
          successMessage: questSuccessMessage.value,
          limit: questLimit.value,
        })
        .then(() => {
          getQuests();
          emptyQuestFields();
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response?.data?.message) {
            utils.showToastMessageError(err.response?.data?.message);
          } else {
            utils.showToastMessageError(t('other.serverError'));
          }
        });
    }

    async function copyToClipboard(value) {
      await navigator.clipboard.writeText(value);
      utils.showToastMessageSuccess(t('other.clipboardCopyTxt'));
    }

    // when challengeType changes, reset challengeData
    watch(challengeType, () => {
      challengeData.value = '';
    });

    return {
      challenges,
      challengeType,
      challengeData,
      createChallenge,
      createQuest,
      updateQuest,
      quests,
      questStartDate,
      questEndDate,
      questTitle,
      questDescription,
      questChallenges,
      questRequiredChallenges,
      questTermsURL,
      questSuccessMessage,
      questLimit,
      showQuestStartDatePicker,
      showQuestEndDatePicker,
      questId,
      copyToClipboard,
      fillQuestFields,
    };
  },
};
</script>
