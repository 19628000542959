import Axios from 'axios';
import VueCookies from 'vue-cookies';
import { createToast } from 'mosha-vue-toastify';

const APIBaseURL = process.env.VUE_APP_BACKEND_URL;

const adapter = Axios.create({
  baseURL: APIBaseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const badgeGeneratorAdapter = Axios.create({
  baseURL: process.env.VUE_APP_BADGE_GENERATOR_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.VUE_APP_BADGE_GENERATOR_KEY,
  },
});

adapter.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = VueCookies.get('token')
      ? 'Bearer ' + VueCookies.get('token')
      : '';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

adapter.interceptors.response.use(
  (response) => {
    eachRecursive(response.data);
    return response;
  },
  (error) => {
    if (error.response) {
      console.error('Response status :', error.response.status);
      console.error('Response data :', error.response.data);

      if (error.response.status >= 500) {
        console.log('Server error');
        createToast(`Server error, please try again later`, {
          position: 'bottom-center',
          hideProgressBar: true,
          timeout: 3000,
          transition: 'slide',
          toastBackgroundColor: '#ff4545',
        });
      }

      // check if authentication error
      if (error.response.data.code === 3000) {
        VueCookies.remove('token');
        window.location.href = '/signin';
      }
    } else if (error.request) {
      createToast(`Can't reach server, please try again later`, {
        position: 'bottom-center',
        hideProgressBar: true,
        timeout: 3000,
        transition: 'slide',
        toastBackgroundColor: '#ff4545',
      });
    } else {
      console.error('Error setting up request:', error.message);
    }
    return Promise.reject(error);
  }
);

badgeGeneratorAdapter.interceptors.request.use(
  (config) => {
    config.headers['Token'] = VueCookies.get('token')
      ? 'Bearer ' + VueCookies.get('token')
      : '';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function eachRecursive(obj) {
  for (var k in obj) {
    if (typeof obj[k] == 'object' && obj[k] !== null) eachRecursive(obj[k]);
    else if (obj[k] == null) obj[k] = '';
  }
}

async function updateIssuer(body, file) {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }
  for (const key in body) {
    if (body[key] !== null) formData.append(key, body[key]);
  }

  return adapter.put('/managers', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function createIssuer(body, file) {
  const formData = new FormData();
  formData.append('file', file);
  for (const key in body) {
    if (body[key] !== null) formData.append(key, body[key]);
  }

  return adapter.post('/managers', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function deleteIssuer() {
  return adapter.delete('/managers');
}

async function getIssuer() {
  return adapter.get('/managers');
}

async function isIssuerRegistered(did) {
  return adapter.get(`/managers/registered/${did}`);
}

async function getIssuerTemplates() {
  return adapter.get('/managers/templates');
}

async function getIssuerTypeTemplates(type) {
  return adapter.get(`/managers/templates/type/${type}`);
}

async function getIssuerTemplate(id) {
  return adapter.get(`/managers/templates/${id}`);
}

async function updateTemplate(body, file, type) {
  const formData = new FormData();
  if (file) {
    formData.append('file', file);
  }

  for (const key in body) {
    if (body[key] !== null) {
      formData.append(key, body[key]);
    }
  }

  return adapter.put(`/managers/templates/${type.toLowerCase()}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function publishTemplate(id) {
  return adapter.post(`/managers/templates/publish/${id}`);
}

async function deleteTemplate(id) {
  return adapter.delete(`/managers/templates/${id}`);
}

async function createTemplate(body, file, type) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  for (const key in body) {
    if (body[key] !== null) {
      formData.append(key, body[key]);
    }
  }

  return adapter.post(`/managers/templates/${type.toLowerCase()}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

async function createSession(body) {
  return adapter.post(`/sessions`, body);
}

async function sendOpenSessionByEmail(body) {
  return adapter.post(`/sessions/send`, body);
}

async function updateSessionNote(body) {
  return adapter.put(`/sessions/note`, body);
}

async function createSessionBuilder(body) {
  return adapter.post(`/sessions/builders`, body);
}

async function retriggerExpiredSession(body) {
  return adapter.put(`/sessions/resend`, body);
}

async function getCommunityChallenges() {
  return adapter.get(`/managers/challenges`);
}

async function createCommunityChallenge(body) {
  return adapter.post(`/managers/challenges`, body);
}

async function getCommunityQuests() {
  return adapter.get(`/managers/quests`);
}

async function createCommunityQuest(body) {
  return adapter.post(`/managers/quests`, body);
}

async function updateCommunityQuest(questId, body) {
  return adapter.put(`/managers/quests/${questId}`, body);
}

export default {
  install(app) {
    const sseHandlers = [];
    let evtSource = null;

    let api = {
      getCitiesFromQuery(countryCode, query) {
        return adapter.get(`/cities/query/${countryCode}/${query}`);
      },
      getCityFromDetails(country, name) {
        return adapter.get(`/cities/details/${country}/${name}`);
      },
      createAuthChallenge(body) {
        return adapter.post('/auth/', body);
      },
      getAuthChallenge(challengeId) {
        return adapter.get(`/auth/${challengeId}`);
      },
      submitAuthVP(body) {
        return adapter.post('/auth/vp/', body);
      },
      sendIssuerPromoteRequest(body) {
        return adapter.post('/managers/promote', body);
      },
      createEmailVerification(body) {
        return adapter.post('/managers/verifications/email', body);
      },
      submitEmailValidation(body) {
        return adapter.put('/managers/verifications/email', body);
      },
      waitChallengeValidation(challenge, next) {
        sseHandlers[challenge] = new EventSource(
          APIBaseURL + '/auth/sse/' + challenge,
          {
            xhrHeaders: {
              'Content-Type': 'text/event-stream',
              Connection: 'keep-alive',
            },
          }
        );

        sseHandlers[challenge].addEventListener(
          'message',
          (event) => {
            const response = JSON.parse(event.data).data;
            if (
              response.status == 'validated' &&
              response.challenge == challenge
            ) {
              sseHandlers[challenge].close();
              return next(response);
            } else if (response.status == 'expired') {
              sseHandlers[challenge].close();
              return next(response);
            } else {
              next(response);
            }
          },
          false
        );

        sseHandlers[challenge].addEventListener(
          'error',
          (event) => {
            if (event.readyState === EventSource.CLOSED) {
              console.log('Event was closed');
              console.log(EventSource);
            }
          },
          false
        );
        return sseHandlers[challenge];
      },
      waitOperationValidation(operationId, next) {
        sseHandlers[operationId] = new EventSource(
          APIBaseURL + '/operations/sse/' + operationId,
          {
            xhrHeaders: {
              'Content-Type': 'text/event-stream',
              Connection: 'keep-alive',
            },
          }
        );

        sseHandlers[operationId].addEventListener(
          'message',
          (event) => {
            const response = JSON.parse(event.data);
            console.log('Receive elements from stream : ', response);
            next(response);
            if (['success', 'error', 'canceled'].includes(response.status)) {
              sseHandlers[operationId].close();
              return;
            }
          },

          false
        );

        sseHandlers[operationId].addEventListener(
          'error',
          (event) => {
            if (event.readyState === EventSource.CLOSED) {
              console.log('Event was closed');
              console.log(EventSource);
            }
          },
          false
        );
        return sseHandlers[operationId];
      },
      updateIssuer,
      createIssuer,
      deleteIssuer,
      getIssuer,
      isIssuerRegistered,
      getIssuerTemplates,
      getIssuerTypeTemplates,
      getIssuerTemplate,
      updateTemplate,
      createTemplate,
      deleteTemplate,
      publishTemplate,
      createSession,
      sendOpenSessionByEmail,
      updateSessionNote,
      createSessionBuilder,
      retriggerExpiredSession,
      getCommunityChallenges,
      createCommunityChallenge,
      getCommunityQuests,
      createCommunityQuest,
      updateCommunityQuest,
    };
    app.provide('api', api);
  },
};

export {
  getIssuer,
  isIssuerRegistered,
  deleteIssuer,
  getIssuerTemplates,
  getIssuerTypeTemplates,
  getIssuerTemplate,
  updateTemplate,
  createTemplate,
  deleteTemplate,
  publishTemplate,
  createSession,
  sendOpenSessionByEmail,
  updateSessionNote,
  createSessionBuilder,
  retriggerExpiredSession,
};
