<template>
  <div>
    <TourGuide ref="tourGuideRef" @finish="finishTour()"></TourGuide>
    <div class="fixed top-0 left-0 w-full h-full">
      <div class="absolute w-full h-full bg-black bg-opacity-20"></div>
    </div>
    <div
      v-if="
        !waitingSignature &&
        !creationSuccessful &&
        !updateSuccessful &&
        !sendingSuccessful &&
        !sendingInProgress
      "
    >
      <div
        class="fixed w-modal-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-5 py-4 border rounded-xl shadow-md z-50"
      >
        <div class="flex items-center">
          <p class="text-lg text-gray-500 ml-2 mr-1.5 my-2">
            {{
              template && !isClone
                ? $t('templateCreation.editTitle')
                : $t('templateCreation.createTitle')
            }}
          </p>
          <div class="grow"></div>
          <div
            v-if="!noCloseOption"
            @click="triggerCloseEvent"
            class="text-xl text-gray-400 mr-2 cursor-pointer"
          >
            <icon name="cross" class="text-sm fill-gray-300" />
          </div>
        </div>
        <div v-if="currentIssuerInfo" class="flex gap-10 px-10 pt-10 pb-5">
          <div
            class="relative px-10"
            @mouseenter="
              templateImagePreview ? (showEditPictureButton = true) : null
            "
            @mouseleave="showEditPictureButton = false"
          >
            <div class="flex gap-3">
              <p
                v-if="['Role'].includes(type)"
                @click="
                  templateShape =
                    'shape_' +
                    Math.max(1, parseInt(templateShape.split('_')[1]) - 1)
                "
                class="text-xl cursor-pointer mt-[105px]"
              >
                <icon name="triangle-left" class="fill-gray-300" />
              </p>
              <TemplatePreview
                v-if="currentIssuerInfo"
                ref="templatePreviewRef"
                :templateImage="templateImagePreview"
                :templateName="
                  templateName
                    ? templateName
                    : $t('templateCreation.defaultTemplateTitle')
                "
                :templateLocation="
                  type == 'Participation' || type == 'Ticket'
                    ? templateLocationVirtual
                      ? $t('templateDisplay.virtualTxt')
                      : templateLocation && templateLocation.name
                      ? templateLocation.name
                      : null
                    : null
                "
                :templateStartDate="
                  type == 'Participation' || type == 'Ticket'
                    ? templateStartDateFormatted
                    : null
                "
                :templateEndDate="
                  type == 'Participation' || type == 'Ticket'
                    ? templateEndDateFormatted
                    : null
                "
                :templateThemeBgColor="templateBgColor"
                :templateThemeTextColor="templateTextColor"
                :templateThemeEffect="templateEffect"
                :templateThemeTexture="templateTexture"
                :templateThemeIsLogoBack="templateIsLogoBack"
                :templateThemeShape="templateShape"
                :issuerName="currentIssuerInfo.name"
                :issuerImage="currentIssuerInfo.profileImage"
                :type="type"
                :is3d="true"
                :isRoleNotLarge="true"
              />
              <p
                v-if="['Role'].includes(type)"
                @click="
                  templateShape =
                    'shape_' +
                    Math.min(6, parseInt(templateShape.split('_')[1]) + 1)
                "
                class="text-xl cursor-pointer mt-[105px]"
              >
                <icon name="triangle-right" class="fill-gray-300" />
              </p>
            </div>
            <div
              v-if="!['Role'].includes(type)"
              class="flex justify-center gap-2 mt-2"
            >
              <button
                class="border text-lg border-gray-300 rounded-lg px-2 py-1"
                @click="templatePreviewRef.flipTemplate"
              >
                <icon name="rotate" />
              </button>
            </div>
          </div>
          <div class="grow flex flex-col gap-2 mb-5">
            <div class="w-full flex gap-2">
              <div
                v-if="
                  [
                    'Basic',
                    'Community',
                    'Participation',
                    'Ticket',
                    'Role',
                  ].includes(type)
                "
                class="flex-none w-[60%]"
              >
                <div class="flex items-center">
                  <p class="font-medium mr-1">
                    {{ $t('templateCreation.titleTxt') }}
                  </p>
                  <icon name="info" class="fill-gray-400 w-4 h-4" />
                </div>
                <input
                  id="tg-createTemplate-title"
                  class="w-full max-h-[28rem] overflow-y-auto border-gray-200 resize-none border-[1px] focus:border-gray-300 focus:ring-0 placeholder:text-gray-900 placeholder:text-sm hover:bg-gray-light cursor-pointer rounded-md py-1 px-2 mb-1.5"
                  :placeholder="$t('templateCreation.titlePlaceholder')"
                  maxlength="100"
                  v-model="templateName"
                />
              </div>
              <div
                v-if="['Membership'].includes(type)"
                class="flex-none w-[40%]"
              >
                <p class="text-xl font-bold mt-3 my-5">
                  {{ $t('templateCreation.membershipDefaultTitle') }}
                </p>
              </div>
              <div class="flex-none w-[40%]">
                <div class="flex items-center">
                  <p class="font-medium mr-1">Picture</p>
                  <icon name="info" class="fill-gray-400 w-4 h-4" />
                </div>
                <button
                  id="tg-createTemplate-image"
                  class="w-full text-gray-500 text-sm border border-gray-300 border-dashed cursor-pointer py-1.5 px-2"
                  @click.stop="
                    () => {
                      showImageHandler = true;
                    }
                  "
                >
                  <p class="text-gray-500 text-sm break-all line-clamp-1">
                    <icon name="image" class="mr-1.5 fill-gray-400" />{{
                      templateImageFile &&
                      templateImageFile.name &&
                      templateImageFile.name != 'undefined'
                        ? templateImageFile.name
                        : $t('templateCreation.addPictureButton')
                    }}
                  </p>
                </button>
              </div>
            </div>

            <div id="tg-createTemplate-description" class="flex flex-col">
              <div class="text-gray-900 flex items-center mb-2">
                <p class="text-gray-900 font-medium mr-1">
                  {{ $t('templateCreation.descriptionTxt') }}
                </p>
                <icon name="info" class="fill-gray-400 w-4 h-4" />
              </div>
              <div class="grow">
                <textarea
                  oninput='this.style.height = "";this.style.height = (this.scrollHeight > 96 ? 96 : this.scrollHeight) + "px"'
                  class="w-full max-h-[28rem] overflow-y-auto border-gray-200 resize-none border-[1px] focus:border-gray-300 focus:ring-0 placeholder:text-gray-900 placeholder:text-sm hover:bg-gray-light cursor-pointer rounded-md py-1 px-2"
                  :placeholder="$t('templateCreation.descriptionPlaceholder')"
                  v-model="templateDescription"
                  rows="4"
                  maxlength="2000"
                />
              </div>
            </div>
            <div
              v-if="['Participation', 'Ticket'].includes(type)"
              class="flex flex-row gap-20 w-full items-center mt-3"
            >
              <div
                id="tg-createTemplate-startDate"
                v-if="['Participation', 'Ticket'].includes(type)"
                class="flex flex-row items-center"
              >
                <div class="text-gray-400 flex items-center mr-10">
                  <p class="text-gray-900 font-medium mr-1.5">
                    {{ $t('templateCreation.startDateTxt') }}
                  </p>
                  <icon name="info" class="fill-gray-400 w-4 h-4" />
                </div>
                <div class="relative">
                  <p
                    @click="showStartDatePicker = !showStartDatePicker"
                    class="w-32 h-7 border border-gray-300 outline text-sm outline-0 hover:bg-gray-light cursor-pointer rounded-md pt-0.5 px-1"
                    :class="{ 'text-gray-300': !templateStartDateFormatted }"
                    click-outside-ignore-startdatepicker
                  >
                    {{
                      templateStartDateFormatted
                        ? templateStartDateFormatted.replace(
                            /T(\d{2}:\d{2}).*/,
                            ' $1'
                          )
                        : ''
                    }}
                  </p>
                  <div
                    v-if="showStartDatePicker"
                    class="absolute w-modal-xs bg-white border shadow-md rounded-md z-10"
                  >
                    <div
                      class="h-fit"
                      click-outside-ignore-startdatepicker-target
                      v-click-outside="
                        () => {
                          showStartDatePicker = false;
                        }
                      "
                    >
                      <datepicker
                        class="bg-gray-200 border-none"
                        :inline="true"
                        menu-class-name="w-modal-xs"
                        v-model="startDatePickerValue"
                        :preview-format="formatDateWithLocation"
                        @update:model-value="
                          () => {
                            templateStartDate = startDatePickerValue;
                            showStartDatePicker = false;
                          }
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="tg-createTemplate-endDate"
                v-if="['Participation', 'Ticket'].includes(type)"
                class="flex flex-row items-center"
              >
                <div class="text-gray-400 flex flex-row items-center mr-10">
                  <p class="text-gray-900 font-medium mr-1.5">
                    {{ $t('templateCreation.endDateTxt') }}
                  </p>
                  <icon name="info" class="fill-gray-400 w-4 h-4" />
                </div>
                <div class="relative">
                  <p
                    @click="showEndDatePicker = !showEndDatePicker"
                    class="w-32 h-7 border border-gray-300 outline text-sm outline-0 hover:bg-gray-light cursor-pointer rounded-md pt-0.5 px-1"
                    :class="{ 'text-gray-300': !templateEndDateFormatted }"
                    click-outside-ignore-enddatepicker
                  >
                    {{
                      templateEndDateFormatted
                        ? templateEndDateFormatted.replace(
                            /T(\d{2}:\d{2}).*/,
                            ' $1'
                          )
                        : ''
                    }}
                  </p>
                  <div
                    v-if="showEndDatePicker"
                    class="absolute w-modal-xs bg-white border shadow-md rounded-md z-10"
                  >
                    <div
                      class="h-fit"
                      click-outside-ignore-enddatepicker-target
                      v-click-outside="
                        () => {
                          showEndDatePicker = false;
                        }
                      "
                    >
                      <datepicker
                        class="bg-gray-200 border-none"
                        :inline="true"
                        menu-class-name="w-modal-xs"
                        v-model="endDatePickerValue"
                        :preview-format="formatDateWithLocation"
                        @update:model-value="
                          () => {
                            templateEndDate = endDatePickerValue;
                            showEndDatePicker = false;
                          }
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="tg-createTemplate-virtual"
              v-if="['Participation', 'Ticket'].includes(type)"
              class="flex my-2"
            >
              <div class="flex flex-row items-center w-32 mr-0">
                <p class="text-gray-900 font-medium mr-1.5">
                  {{ $t('templateCreation.virtualTxt') }}
                </p>
                <icon name="info" class="fill-gray-400 w-4 h-4" />
              </div>
              <div class="px-1">
                <ToggleButton v-model="templateLocationVirtual"></ToggleButton>
              </div>
            </div>
            <div
              id="tg-createTemplate-location"
              v-if="
                ['Participation', 'Ticket'].includes(type) &&
                !templateLocationVirtual
              "
              class="flex mb-5"
            >
              <div
                v-if="citiesAutoComplete && citiesAutoComplete.length > 0"
                class="relative"
              >
                <div
                  v-if="!templateLocation"
                  class="absolute w-[25rem] top-7 left-[18.8rem] bg-white px-3 py-2 border rounded-xl shadow-md max-h-80 overflow-y-auto z-20"
                >
                  <p
                    v-for="city in citiesAutoComplete"
                    :key="city"
                    @click="
                      () => {
                        locationQuery = city.name;
                        templateLocation = city;
                      }
                    "
                    class="text-sm font-medium my-1 hover:bg-gray-light rounded-md cursor-pointer"
                  >
                    {{ city.name }} ({{ city.state_name }})
                  </p>
                </div>
              </div>

              <div class="flex flex-row items-center w-32 mr-0">
                <p class="text-gray-900 font-medium mr-1.5">
                  {{ $t('templateCreation.locationTxt') }}
                </p>
                <icon name="info" class="fill-gray-400 w-4 h-4" />
              </div>
              <div v-if="showCountryPicker" class="relative">
                <div
                  class="absolute w-[25rem] top-7 left-0 bg-white px-3 py-2 border rounded-xl shadow-md max-h-40 overflow-y-auto z-20"
                  click-outside-ignore-countrypicker-target
                  v-click-outside="
                    () => {
                      showCountryPicker = false;
                    }
                  "
                >
                  <p
                    v-for="country in countries"
                    :key="country"
                    @click="
                      () => {
                        locationCountry = country;
                        templateLocation = null;
                        locationQuery = '';
                        showCountryPicker = false;
                      }
                    "
                    class="text-sm font-medium my-1 hover:bg-gray-light rounded-md cursor-pointer"
                  >
                    {{ country.name }}
                  </p>
                </div>
              </div>
              <div class="flex-none w-36 relative">
                <div
                  @click="
                    () => {
                      showCountryPicker = !showCountryPicker;
                    }
                  "
                  class="absolute left-0 -top-0.5 cursor-pointer"
                  click-outside-ignore-countrypicker
                >
                  <div
                    class="flex items-center border shadow-md rounded-lg gap-1 px-2 py-1"
                  >
                    <div v-if="locationCountry" class="w-8 h-5 relative">
                      <country-flag
                        :country="locationCountry.iso2"
                        class="absolute bottom-0 left-0 pt-0.5"
                      />
                    </div>
                    <div
                      v-else
                      class="w-8 h-5 bg-gray-light flex items-center justify-center rounded-md"
                    >
                      <icon name="flag" class="fill-gray-400 text-xs m-1.5" />
                    </div>
                    <p class="text-sm text-gray-400 font-medium ml-1">
                      {{ $t('templateCreation.countryTxt') }}
                    </p>
                    <icon name="chevron-down" class="fill-gray-400" />
                  </div>
                </div>
              </div>
              <div class="grow flex overflow-hidden">
                <input
                  v-if="!templateLocation"
                  class="w-full max-h-[28rem] border border-gray-300 focus:border-transparent focus:ring-0 text-sm placeholder:text-gray-700 hover:bg-gray-light cursor-pointer rounded-md py-1 px-1 mb-1"
                  :placeholder="$t('templateCreation.locationPlaceholder')"
                  :disabled="!locationCountry"
                  v-model="locationQuery"
                />
                <div v-else class="w-full flex gap-2">
                  <p>
                    {{ templateLocation.name }}
                  </p>
                  <div
                    @click="
                      () => {
                        templateLocation = null;
                        locationQuery = '';
                      }
                    "
                    class="cursor-pointer"
                  >
                    <p>
                      <icon name="cross" class="text-xs fill-gray-500 mb-0.5" />
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="templateLocation" class="hover:bg-gray-light"></div>
            </div>
            <div
              v-if="
                [
                  'Basic',
                  'Community',
                  'Participation',
                  'Ticket',
                  'Role',
                ].includes(type)
              "
              class="grid gap-4 py-2"
              :class="{ 'grid-cols-2': type != 'Role' }"
            >
              <div class="col-span-1">
                <div
                  v-if="
                    ['Basic', 'Community', 'Participation', 'Ticket'].includes(
                      type
                    )
                  "
                  class="text-gray-400 mb-4"
                >
                  <p class="text-gray-900 font-medium mb-1">
                    {{ $t('templateCreation.themeColorTxt') }}
                    <icon name="info" class="fill-gray-400 w-4 h-4" />
                  </p>
                </div>
                <div v-if="['Role'].includes(type)" class="text-gray-400 mb-4">
                  <p class="text-gray-900 font-medium mb-1">
                    {{ $t('templateCreation.shapeThemeTxt') }}
                    <icon name="info" class="fill-gray-400 w-4 h-4" />
                  </p>
                </div>
                <div :class="{ 'grid grid-cols-2': type == 'Role' }">
                  <div
                    id="tg-createTemplate-backgroundColor"
                    v-if="
                      [
                        'Role',
                        'Basic',
                        'Community',
                        'Participation',
                        'Ticket',
                      ].includes(type)
                    "
                    class="flex items-center mb-2"
                  >
                    <div class="mr-2">
                      <div
                        @click="showColorPicker = !showColorPicker"
                        click-outside-ignore-colorpicker
                        class="w-6 h-6 inline-flex rounded-full cursor-pointer mt-0.5 border border-gray-300"
                        :style="`background-color:${templateBgColor}`"
                      ></div>
                      <div v-if="showColorPicker" class="relative">
                        <div
                          class="absolute top-1 z-10 flex"
                          v-click-outside="
                            () => {
                              showColorPicker = false;
                            }
                          "
                          click-outside-ignore-colorpicker-target
                        >
                          <ColorPicker
                            v-if="templateBgColor"
                            theme="light"
                            :color="templateBgColor"
                            :sucker-hide="true"
                            @changeColor="
                              (color) => {
                                templateBgColor = color.hex;
                              }
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="pb-1">
                      {{ $t('templateCreation.backgroundColorTxt') }}
                    </div>
                  </div>
                  <div
                    id="tg-createTemplate-shape"
                    v-if="['Role'].includes(type)"
                    class="flex gap-2"
                  >
                    <p
                      v-for="i in 6"
                      :key="i"
                      @click="templateShape = `shape_${i}`"
                    >
                      <icon
                        :name="`shape_${i}`"
                        :class="
                          templateShape == `shape_${i}`
                            ? 'fill-black'
                            : 'fill-gray-400'
                        "
                        class="w-7 h-7 cursor-pointer"
                      />
                    </p>
                  </div>
                  <div
                    id="tg-createTemplate-color"
                    v-if="
                      [
                        'Basic',
                        'Community',
                        'Participation',
                        'Ticket',
                      ].includes(type)
                    "
                    class="flex items-center"
                  >
                    <div class="mr-2">
                      <div
                        @click="showTextColorPicker = !showTextColorPicker"
                        click-outside-ignore-colorpicker
                        class="w-6 h-6 inline-flex rounded-full cursor-pointer mt-0.5 border border-gray-300"
                        :style="`background-color:${templateTextColor}`"
                      ></div>
                      <div v-if="showTextColorPicker" class="relative">
                        <div
                          class="absolute top-1 z-10 flex"
                          v-click-outside="
                            () => {
                              showTextColorPicker = false;
                            }
                          "
                          click-outside-ignore-colorpicker-target
                        >
                          <ColorPicker
                            v-if="templateTextColor"
                            theme="light"
                            :color="templateTextColor"
                            :sucker-hide="true"
                            @changeColor="
                              (color) => {
                                templateTextColor = color.hex;
                              }
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="pb-1">
                      <p>
                        {{ $t('templateCreation.textColorTxt') }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  ['Basic', 'Community', 'Participation', 'Ticket'].includes(
                    type
                  )
                "
                class="col-span-1"
              >
                <p class="text-gray-900 font-medium mb-4">
                  {{ $t('templateCreation.texturesAndEffectsTxt') }}
                  <icon name="info" class="fill-gray-400 w-4 h-4" />
                </p>
                <div
                  id="tg-createTemplate-isLogoBack"
                  class="flex items-center mb-3"
                >
                  <div class="flex-none text-gray-400 flex items-center mr-2">
                    <p class="text-black">
                      {{ $t('templateCreation.isLogoBackTxt') }}
                    </p>
                  </div>
                  <div class="relative">
                    <ToggleButton
                      class="absolute -top-2"
                      v-model="templateIsLogoBack"
                    ></ToggleButton>
                  </div>
                </div>
                <div
                  id="tg-createTemplate-texture"
                  v-if="
                    ['Basic', 'Community', 'Participation', 'Ticket'].includes(
                      type
                    )
                  "
                  class="flex flex-col gap-2"
                >
                  <select
                    v-model="templateEffect"
                    class="bg-white border border-gray-300 rounded-md text-sm text-gray-900 px-2 mr-2 w-full"
                  >
                    <option value="glare">
                      {{ $t('templateCreation.textureGlareTxt') }}
                    </option>
                    <option value="texture_modulate">
                      {{ $t('templateCreation.textureModulateTxt') }}
                    </option>
                    <option value="texture_glare">
                      {{ $t('templateCreation.textureShinyTxt') }}
                    </option>
                    <option value="texture_hue">
                      {{ $t('templateCreation.textureHueTxt') }}
                    </option>
                    <option value="texture_difference">
                      {{ $t('templateCreation.textureDifferenceTxt') }}
                    </option>
                  </select>
                  <div v-if="templateEffect != 'glare'" class="flex flex-row">
                    <TextureSelector
                      v-model="templateTexture"
                      @update:modelValue="(value) => (templateTexture = value)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            ['Basic', 'Community', 'Participation', 'Ticket', 'Role'].includes(
              type
            )
          "
          class="flex items-center px-5 mb-2"
        >
          <p
            @click="showAdvanced = !showAdvanced"
            class="text-gray-900 font-medium ml-1 cursor-pointer"
          >
            <icon name="advanced" class="fill-gray-500 w-5 h-5 mr-1.5" />{{
              $t('templateCreation.advancedTxt')
            }}
          </p>
        </div>
        <div
          v-if="
            showAdvanced &&
            ['Basic', 'Community', 'Participation', 'Ticket', 'Role'].includes(
              type
            )
          "
        >
          <div class="columns-3 space-y-2 max-h-[12rem] gap-4 px-5 py-2">
            <div
              id="tg-createTemplate-validity"
              v-if="['Basic', 'Role'].includes(type)"
              class="flex items-center"
            >
              <p class="flex-none flex w-32 items-center">
                <icon name="info" class="fill-gray-400 w-4 h-4 mr-2" />
                {{ $t('templateCreation.validityTxt') }}
              </p>
              <div
                class="grow relative w-full h-9 border-gray-300 border-[1px] rounded-md"
              >
                <div
                  @click="showValidityMenu = !showValidityMenu"
                  class="w-full h-full flex items-center hover:bg-gray-light cursor-pointer rounded-md"
                >
                  <p
                    class="rounded-md text-gray-700 text-sm px-1 h-7 flex items-center"
                    :class="{ 'text-gray-300': !templateValidity }"
                    click-outside-ignore-validitypicker
                  >
                    {{
                      templateValidity
                        ? templateValidityFormatted
                        : $t('templateCreation.validityPlaceholder')
                    }}
                  </p>
                </div>

                <div
                  v-if="showValidityMenu"
                  class="absolute w-modal-xs max-h-[12rem] overflow-y-scroll bg-white border shadow-md rounded-md z-10"
                  v-click-outside="
                    () => {
                      showValidityMenu = false;
                    }
                  "
                  click-outside-ignore-validitypicker-target
                >
                  <p v-if="templateValidity" class="font-medium text-sm p-2">
                    {{ templateValidityFormatted }}
                  </p>
                  <hr v-if="templateValidity" class="border-b" />
                  <p
                    @click="
                      () => {
                        templateValidityChoice = 'infinite';
                        templateValidity = 'infinite';
                        showValidityMenu = false;
                        showValidityMenuDate = false;
                        showValidityMenuDuration = false;
                      }
                    "
                    class="font-medium text-sm hover:bg-gray-light cursor-pointer p-2"
                  >
                    <icon
                      name="infinity"
                      class="fill-transparent mr-1 pt-0.5"
                    />{{ $t('templateCreation.infiniteTxt') }}
                  </p>
                  <p
                    @click="
                      () => {
                        showValidityMenuDuration = false;
                        showValidityMenuDate = !showValidityMenuDate;
                      }
                    "
                    class="font-medium text-sm hover:bg-gray-light cursor-pointer p-2"
                  >
                    <icon name="calendar-event" class="mr-1 pt-0.5" />{{
                      $t('templateCreation.expireTxt')
                    }}
                  </p>
                  <div v-if="showValidityMenuDate" class="h-fit">
                    <datepicker
                      class="bg-gray-200 border-none"
                      :inline="true"
                      :enable-time-picker="false"
                      menu-class-name="w-modal-xs"
                      :preview-format="utils.formatSimpleDate"
                      v-model="datePickerValue"
                      @update:model-value="
                        () => {
                          templateValidity = datePickerValue;
                          templateValidityChoice = 'expirationDate';
                          showValidityMenu = false;
                        }
                      "
                    />
                  </div>
                  <p
                    @click="
                      () => {
                        showValidityMenuDate = false;
                        showValidityMenuDuration = !showValidityMenuDuration;
                      }
                    "
                    class="font-medium text-sm hover:bg-gray-light cursor-pointer p-2"
                  >
                    <icon name="clock" class="mr-1 pt-0.5" />{{
                      $t('templateCreation.durationTxt')
                    }}
                  </p>
                  <div
                    v-if="showValidityMenuDuration"
                    class="grid grid-cols-4 gap-2 p-3 pr-5"
                  >
                    <div
                      v-for="duration of durationMonthValue"
                      :key="duration"
                      @click="
                        () => {
                          templateValidity = duration;
                          templateValidityChoice = 'expirationDuration';
                          showValidityMenu = false;
                        }
                      "
                      class="text-sm text-center rounded-lg cursor-pointer p-2"
                      :class="
                        duration == templateValidity
                          ? 'bg-secondary text-white'
                          : 'hover:bg-gray-light'
                      "
                    >
                      {{
                        duration +
                        ' ' +
                        (duration > 1
                          ? $t('templateCreation.monthsTxt')
                          : $t('templateCreation.monthTxt'))
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="tg-createTemplate-public"
              v-if="
                !['Membership'].includes(type) &&
                (!template || (template && isClone))
              "
              class="flex items-center"
            >
              <p class="flex-none flex w-32 items-center">
                <icon name="info" class="fill-gray-400 w-4 h-4 mr-2" />
                {{ $t('templateCreation.draftTxt') }}
              </p>
              <ToggleButton
                class="pt-1.5"
                v-model="templateDraftMode"
              ></ToggleButton>
            </div>
            <div
              id="tg-createTemplate-quantity"
              v-if="['Participation'].includes(type)"
              class="flex items-center"
            >
              <p class="flex-none flex w-32 items-center">
                <icon name="info" class="fill-gray-400 w-4 h-4 mr-2" />
                {{ $t('templateCreation.quantityTxt') }}
              </p>
              <input
                class="w-full focus:border-gray-300 focus:ring-0 placeholder:text-sm placeholder:text-gray-700 hover:bg-gray-light cursor-pointer rounded-md py-1 px-1 border-[1px] border-gray-300"
                :placeholder="$t('templateCreation.quantityPlaceholder')"
                v-model="templateQuantity"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
              />
            </div>
            <div
              id="tg-createTemplate-timestamp"
              v-if="['Basic', 'Role'].includes(type)"
              class="flex items-center"
            >
              <p class="flex-none flex w-32 items-center">
                <icon name="info" class="fill-gray-400 w-4 h-4 mr-2" />
                {{ $t('templateCreation.timestampTxt') }}
              </p>
              <ToggleButton
                class="pt-1.5"
                v-model="templateTimestamped"
              ></ToggleButton>
            </div>
            <!-- <div class="flex">
              <div class="flex-none w-[90px] mr-2">
                <p>
                  <icon name="info" class="fill-gray-400 w-4 h-4 mr-1" />{{
                    $t('templateCreation.urlTxt')
                  }}
                </p>
              </div>

              <input
                class="w-full focus:border-gray-300 focus:ring-0 placeholder:text-sm placeholder:text-gray-700 hover:bg-gray-light cursor-pointer rounded-md py-1 px-1 border-[1px] border-gray-300"
                placeholder="https://"
              />
            </div> -->
            <div
              id="tg-createTemplate-criteria"
              v-if="
                [
                  'Basic',
                  'Community',
                  'Participation',
                  'Ticket',
                  'Role',
                ].includes(type)
              "
              class="flex mb-5"
            >
              <p class="flex-none flex w-32 items-center h-8">
                <icon name="info" class="fill-gray-400 w-4 h-4 mr-2" />
                {{ $t('templateCreation.criteriaTxt') }}
              </p>
              <div class="grow">
                <textarea
                  oninput='this.style.height = "";this.style.height = (this.scrollHeight > 96 ? 96 : this.scrollHeight) + "px"'
                  class="w-full max-h-[28rem] overflow-y-auto resize-none border-gray-300 text-sm placeholder:text-gray-700 hover:bg-gray-light cursor-pointer focus:border-gray-300 focus:ring-0 rounded-md py-1 px-2"
                  :placeholder="$t('templateCreation.criteriaPlaceholder')"
                  v-model="templateCriteria"
                  rows="3"
                  maxlength="2000"
                />
              </div>
            </div>

            <div
              v-if="['Basic', 'Participation', 'Ticker', 'Role'].includes(type)"
              class="flex items-start"
            >
              <p class="flex-none flex w-32 items-center">
                <icon name="info" class="fill-gray-400 w-4 h-4 mr-2" />
                {{ $t('templateCreation.pointsTxt') }}
              </p>

              <div>
                <input
                  class="grow focus:border-gray-300 focus:ring-0 placeholder:text-sm placeholder:text-gray-700 hover:bg-gray-light cursor-pointer rounded-md py-1 px-1 border-[1px] border-gray-300"
                  :placeholder="$t('templateCreation.pointsPlaceholder')"
                  v-model="templatePoints"
                  oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                />
                <div class="flex items-center">
                  <ToggleButton
                    @click="
                      () => {
                        templatePointNegative = !templatePointNegative;
                      }
                    "
                    class="pt-1.5 mr-2"
                    :model-value="!templatePointNegative"
                  ></ToggleButton>
                  <p class="text-sm text-gray-700">Give points</p>
                </div>
                <div class="flex items-center">
                  <ToggleButton
                    class="pt-1.5 mr-2"
                    v-model="templatePointNegative"
                  ></ToggleButton>
                  <p class="text-sm text-gray-700">Spend points</p>
                </div>
              </div>
            </div>
            <div
              id="tg-createTemplate-ticketOptions"
              v-if="['Ticket'].includes(type)"
              class="flex"
            >
              <div class="grow flex flex-col gap-2 px-1">
                <div class="flex gap-2">
                  <p class="flex-none flex w-32 items-center">
                    <icon name="info" class="fill-gray-400 w-4 h-4 mr-2" />
                    {{ $t('templateCreation.optionnalDetailsNameTxt') }}
                  </p>
                  <ToggleButton
                    class="pt-1.5"
                    v-model="templateOptionName"
                  ></ToggleButton>
                </div>
                <div class="flex gap-2">
                  <p class="flex-none flex w-32 items-center">
                    <icon name="info" class="fill-gray-400 w-4 h-4 mr-2" />
                    {{ $t('templateCreation.optionnalDetailsSeatTxt') }}
                  </p>
                  <ToggleButton
                    class="pt-1.5"
                    v-model="templateOptionSeat"
                  ></ToggleButton>
                </div>
                <div class="flex gap-2">
                  <p class="flex-none flex w-32 items-center">
                    <icon name="info" class="fill-gray-400 w-4 h-4 mr-2" />
                    {{ $t('templateCreation.optionnalDetailsSectionTxt') }}
                  </p>
                  <ToggleButton
                    class="pt-1.5"
                    v-model="templateOptionSection"
                  ></ToggleButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center mt-5">
          <p @click="startTour" class="cursor-pointer">
            <icon name="question-circle" class="fill-gray-500 w-6 h-6" />
          </p>
          <div class="grow"></div>
          <button
            id="tg-createTemplate-create"
            @click="
              template && !isClone
                ? updateIssuerTemplate()
                : addIssuerTemplate()
            "
            class="text-sm bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2.5 ml-auto mr-3"
          >
            {{
              template && !isClone
                ? $t('templateCreation.saveButton')
                : $t('templateCreation.createButton')
            }}
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="waitingSignature">
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-app.jpg')"
            :height="160"
            :width="160"
            :speed="0.7"
          />
        </div>
        <p
          v-if="waitingSignature == 'signatureAdd'"
          class="text-center text-xl font-bold px-20 mb-1"
        >
          {{
            $t('templateCreation.confirmCreateTxt') +
            ' ' +
            templateLabel.toLowerCase()
          }}
        </p>
        <p
          v-else-if="waitingSignature == 'signatureEdit'"
          class="text-center text-xl font-bold px-20 mb-1"
        >
          {{
            $t('templateCreation.confirmUpdateTxt') +
            ' ' +
            templateLabel.toLowerCase()
          }}
        </p>
        <p class="text-center px-10 mb-4">
          {{ $t('templateCreation.onMydidAppTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="creationSuccessful">
      <BasicSmModal
        v-if="type == 'Membership' && !creationSuccessfulSkip"
        @close="triggerCloseEvent"
        :large="true"
      >
        <p class="text-center text-2xl font-bold mb-5">
          {{ $t('templateCreation.cardModal.titleSection1') }}
        </p>
        <div
          v-if="creationSuccessfulCompleted"
          class="bg-white flex justify-center mb-5"
        >
          <qrcode-vue :value="qrCodeLink" :size="120" level="H" :margin="1" />
        </div>
        <div v-else class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center font-medium px-20 mb-3">
          {{ $t('templateCreation.cardModal.copyTxt') }}
        </p>
        <div
          class="flex w-1/2 border items-center rounded-md mb-2 mx-auto"
          :class="{
            'pointer-events-none opacity-50': !creationSuccessfulCompleted,
          }"
        >
          <input
            type="text"
            rows="1"
            v-model="qrCodeLink"
            class="grow border-none text-gray-600 rounded-l-md pr-0"
            readonly="readonly"
          />
          <div
            class="bg-secondary h-10 items-center flex rounded-r-md cursor-pointer"
            @click="copyToClipboard"
          >
            <p class="text-white text-sm mx-3">
              {{ $t('templateCreation.cardModal.copyButton') }}
            </p>
          </div>
        </div>
        <div
          class="w-1/2 mx-auto mb-7"
          :class="{
            'pointer-events-none opacity-50': !creationSuccessfulCompleted,
          }"
        >
          <div
            class="border overflow-y-scroll no-scrollbar rounded-md py-2 px-2 mb-2"
            ref="emailListScrollDiv"
          >
            <div
              v-if="emailList.length > 0"
              class="flex flex-wrap gap-2 mb-1.5"
            >
              <div
                v-for="(email, index) in emailList"
                :key="index"
                class="bg-violet-50 pl-1.5 pr-1 rounded-md"
              >
                <p class="text-gray-500">
                  {{ email
                  }}<span
                    @click="removeFromEmailList(index)"
                    class="cursor-pointer"
                    ><icon
                      name="cross"
                      class="fill-gray-400 text-xxs ml-2 pb-1"
                  /></span>
                </p>
              </div>
            </div>
            <textarea
              class="w-full border-none border-transparent focus:border-transparent focus:ring-0 resize-none p-0"
              :placeholder="
                emailList.length == 0
                  ? $t('templateCreation.cardModal.emailPlaceholder')
                  : ''
              "
              rows="1"
              v-model="emailInput"
              @keydown.enter.prevent="treatEmailInput"
              @focusout="treatEmailInput"
            />
          </div>
        </div>
        <div
          class="w-1/2 mx-auto"
          :class="{
            'pointer-events-none opacity-50': !creationSuccessfulCompleted,
          }"
        >
          <button
            @click="sendOpenLink()"
            class="w-full bg-secondary text-white shadow-sm rounded-md cursor-pointer py-2 px-2.5 mr-3 mb-2"
          >
            {{ $t('templateCreation.cardModal.sendButton') }}
          </button>
        </div>
        <div class="w-1/2 mx-auto">
          <button
            @click="creationSuccessfulSkip = true"
            class="w-full bg-transparent text-gray-400 border shadow-sm rounded-md cursor-pointer py-2 px-2.5 mr-3 mb-2"
          >
            {{ $t('templateCreation.cardModal.skipNowButton') }}
          </button>
        </div>
      </BasicSmModal>
      <BasicSmModal
        v-else-if="type == 'Membership' && creationSuccessfulSkip"
        @close="triggerCloseEvent"
        :large="true"
        ><div class="w-1/2 mx-auto">
          <p class="text-2xl font-bold mb-5">
            {{ $t('templateCreation.cardModal.titleSection2') }}
          </p>
          <p class="font-medium mb-12">
            {{ $t('templateCreation.cardModal.recommandationTxt') }}
          </p>
        </div>
        <div class="w-1/2 mx-auto">
          <button
            @click="creationSuccessfulSkip = false"
            class="w-full bg-secondary text-white shadow-sm rounded-md cursor-pointer py-2 px-2.5 mr-3 mb-2"
          >
            {{ $t('templateCreation.cardModal.cancelButton') }}
          </button>
        </div>
        <div class="w-1/2 mx-auto">
          <button
            @click="triggerCloseEvent"
            class="w-full bg-transparent text-gray-400 border shadow-sm rounded-md cursor-pointer py-2 px-2.5 mr-3 mb-2"
          >
            {{ $t('templateCreation.cardModal.skipButton') }}
          </button>
        </div>
      </BasicSmModal>
      <BasicSmModal v-else @close="triggerCloseEvent">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-check.jpg')"
            :height="160"
            :width="160"
            :speed="0.5"
            :loop="false"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-5">
          {{ templateLabel + ' ' + $t('templateCreation.createdSuccessTxt') }}
        </p>
        <div class="flex justify-center">
          <button
            @click="triggerCloseEvent"
            class="text-sm bg-secondary text-white shadow-sm rounded-md cursor-pointer py-2 px-7"
          >
            {{ $t('templateCreation.showTemplateButton') }}
          </button>
        </div>
      </BasicSmModal>
    </div>
    <div v-else-if="updateSuccessful">
      <BasicSmModal @close="triggerCloseEvent">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-check.jpg')"
            :height="160"
            :width="160"
            :speed="0.5"
            :loop="false"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-5">
          {{ templateLabel + ' ' + $t('templateCreation.updatedSuccessTxt') }}
        </p>
        <div class="flex justify-center">
          <button
            @click="triggerCloseEvent"
            class="text-sm bg-secondary text-white shadow-sm rounded-md cursor-pointer py-2 px-7"
          >
            {{ $t('templateCreation.showTemplateButton') }}
          </button>
        </div>
      </BasicSmModal>
    </div>
    <ImageHandler
      v-if="showImageHandler"
      :type="type"
      :cropperShape="templateShape"
      @select="
        (file, data) => {
          templateImagePreview = data;
          templateImageFile = file;
          showImageHandler = false;
          showEditPictureButton = false;
        }
      "
      @close="showImageHandler = false"
    ></ImageHandler>
    <div v-else-if="sendingInProgress">
      <BasicSmModal :noCloseOption="true">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-round.jpg')"
            :height="50"
            :width="50"
            :speed="1.5"
          />
        </div>
        <p class="text-center text-gray-400 px-10">
          {{ templateLabel + ' ' + $t('templateSend.sendProgressTxt') }}
        </p>
      </BasicSmModal>
    </div>
    <div v-else-if="sendingSuccessful">
      <BasicSmModal @close="triggerCloseEvent">
        <div class="justify-center mb-5">
          <Vue3Lottie
            :animationLink="require('/assets/images/loader-send.jpg')"
            :height="160"
            :width="160"
            :speed="1"
            :loop="false"
          />
        </div>
        <p class="text-center text-xl font-bold px-20 mb-5">
          {{ templateLabel + ' ' + $t('templateSend.successSendTxt') }}
        </p>
      </BasicSmModal>
    </div>
  </div>
</template>
<style scoped>
.dp__main::v-deep .dp__action_select {
  background-color: rgb(79 70 229) !important;
}
.dp__main::v-deep .dp__active_date {
  background-color: rgb(79 70 229) !important;
}
</style>
<script>
import { ref, computed, watch, inject, onMounted } from 'vue';
import { useStore } from 'vuex';
import { ColorPicker } from 'vue-color-kit';
import tzlookup from 'tz-lookup';
import { useI18n } from 'vue-i18n';
import moment from 'moment-timezone';
import countryList from '../../../assets/countries.json';
import TemplatePreview from '../../components/templates/TemplatePreview.vue';
import ToggleButton from '../../components/ToggleButton.vue';
import BasicSmModal from '../../components/BasicSmModal.vue';
import ImageHandler from '../../components/ImageHandler.vue';
import TourGuide from '../../components/TourGuide.vue';
import TextureSelector from './TextureSelector.vue';
import templateColors from '../../../assets/templateColors.json';

export default {
  props: {
    type: String,
    template: Object,
    noCloseOption: Boolean,
    isClone: Boolean,
  },
  setup(props, context) {
    const store = useStore();
    const utils = inject('utils');
    const api = inject('api');
    const cookies = inject('cookies');
    const { t } = useI18n();

    const currentIssuerInfo = computed(
      () => store.getters.getCurrentIssuerInfo
    );
    const currentIssuerDid = computed(() => store.getters.getCurrentIssuerDid);
    const pointAuthorized = computed(() => {
      return (
        process.env.VUE_APP_POINTS_AUTHORIZED_ISSUER_DIDS &&
        process.env.VUE_APP_POINTS_AUTHORIZED_ISSUER_DIDS.includes(
          currentIssuerDid.value
        )
      );
    });

    // COMMON BASE INFORMATION
    const templateCategory = ref(
      ['Basic', 'Community', 'Participation', 'Membership', 'Role'].indexOf(
        props.type
      )
    );
    if (props.type == 'Ticket') templateCategory.value = 2;
    const templateLabel = ref(utils.templateTypeToLabel(props.type, useI18n()));
    const templateLang = ref('en');
    const templateName = ref(null);
    const templateDescription = ref(null);
    const templateCriteria = ref(null);

    const templateImageFile = ref(null);
    const templateImagePreview = ref(null);
    const templateImageMessage = ref(null);

    const templateId = ref(null);
    const templateLocation = ref(null);
    const templateLocationVirtual = ref(false);
    const templateTimestamped = ref(false);
    const templateDraftMode = ref(false);
    const templateQuantity = ref(null);
    const templatePoints = ref(null);
    const templatePointNegative = ref(false);
    const templateOptionName = ref(false);
    const templateOptionSeat = ref(false);
    const templateOptionSection = ref(false);
    const templateValidity = ref('infinite');
    const templateValidityChoice = ref('infinite');
    const templateBgColor = ref(templateColors[props.type].backgroundCardColor);
    const templateTextColor = ref(templateColors[props.type].textColor);
    const templateIsLogoBack = ref(false);
    const templateShape = ref('shape_1');
    const templateTexture = ref(
      'https://myntfsid.mypinata.cloud/ipfs/bafybeigzxnh2rwpcjgyatlne3cywb4kkmumn3uvb4blihxqwkaonvwdjhy'
    );
    const templateEffect = ref('glare');
    const showTextureSelector = ref(false);
    const showEmojiPicker = ref(false);
    const emojiStock = ref([]);
    const emojiStockIndex = ref(0);
    const showColorPicker = ref(false);
    const showTextColorPicker = ref(false);
    const showValidityMenu = ref(false);
    const showValidityMenuDate = ref(false);
    const startDatePickerValue = ref(null);
    const endDatePickerValue = ref(null);
    const showValidityMenuDuration = ref(false);
    const durationMonthValue = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    const templateStartDate = ref(null);
    const templateStartDateFormatted = ref(null);
    const showStartDatePicker = ref(false);
    const templateEndDate = ref(null);
    const templateEndDateFormatted = ref(null);
    const showEndDatePicker = ref(false);

    const showEditPictureButton = ref(false);
    const showImageHandler = ref(false);

    const newTemplateHash = ref(null);
    const newTemplateId = ref(null);
    const waitingSignature = ref(null);
    const creationSuccessful = ref(false);
    const creationSuccessfulCompleted = ref(false);
    const creationSuccessfulSkip = ref(false);
    const updateSuccessful = ref(false);
    const qrCodeLink = ref('');
    const tourGuideRef = ref();

    const locationQuery = ref('');
    const locationCountry = ref(null);
    const citiesAutoComplete = ref(null);
    const countries = ref(countryList);
    const showCountryPicker = ref(false);
    const locationTimezoneOffset = ref('+00:00');

    const emailList = ref([]);
    const emailListScrollDiv = ref(null);
    const emailInput = ref(null);
    const sendingInProgress = ref(false);
    const sendingSuccessful = ref(false);
    const showAdvanced = ref(false);

    const templatePreviewRef = ref();

    watch(templateIsLogoBack, async () => {
      if (!templatePreviewRef.value) return;
      const templateFlip = await templatePreviewRef.value.getTemplateFlip();
      if (templateFlip == 'front') {
        templatePreviewRef.value.flipTemplate();
      }
    });

    watch(templateImagePreview, async () => {
      if (!templatePreviewRef.value) return;
      const templateFlip = await templatePreviewRef.value.getTemplateFlip();
      if (templateFlip == 'back') {
        templatePreviewRef.value.flipTemplate();
      }
    });

    onMounted(() => {
      const tourGuideCreate = cookies.get('tourGuideCreate');
      if (!tourGuideCreate) {
        startTour();
      }
    });

    if (props.template) {
      if (props.template.data.badgeTheme) {
        templateBgColor.value =
          props.template.data.badgeTheme.backgroundCardColor;
        templateTextColor.value = props.template.data.badgeTheme.textColor;
        templateEffect.value = props.template.data.badgeTheme.cardEffect;
        templateTexture.value = props.template.data.badgeTheme.textureImageURL;
        templateIsLogoBack.value = !!props.template.data.badgeTheme.isLogoBack;
        templateShape.value = props.template.data.badgeTheme.shape;
      }
      templateImagePreview.value = props.template.data.image;
    } else if (props.type == 'Membership') {
      setTimeout(() => {
        utils
          .getBase64FromImageUrl('/7743039b5e5fab9b1262fb07a0e69f19.jpg')
          .then((data) => {
            templateImagePreview.value =
              '/7743039b5e5fab9b1262fb07a0e69f19.jpg';
            templateImageFile.value = utils.base64ToFile(data);
          });
      }, 1000);
    }

    function startTour() {
      tourGuideRef.value.start([
        {
          content: t('tourGuide.createTour.imageTxt'),
          title: t('tourGuide.createTour.imageTitle'),
          id: 'tg-createTemplate-image',
        },
        {
          content: t('tourGuide.createTour.titleTxt'),
          title: t('tourGuide.createTour.titleTitle'),
          id: 'tg-createTemplate-title',
        },
        {
          content: t('tourGuide.createTour.descriptionTxt'),
          title: t('tourGuide.createTour.descriptionTitle'),
          id: 'tg-createTemplate-description',
        },
        {
          content: t('tourGuide.createTour.criteriaTxt'),
          title: t('tourGuide.createTour.criteriaTitle'),
          id: 'tg-createTemplate-criteria',
        },
        {
          content: t('tourGuide.createTour.validityTxt'),
          title: t('tourGuide.createTour.validityTitle'),
          id: 'tg-createTemplate-validity',
        },

        {
          content: t('tourGuide.createTour.timestampTxt'),
          title: t('tourGuide.createTour.timestampTitle'),
          id: 'tg-createTemplate-timestamp',
        },
        {
          content: t('tourGuide.createTour.previewTxt'),
          title: t('tourGuide.createTour.previewTitle'),
          id: 'tg-createTemplate-preview',
        },
        {
          content: t('tourGuide.createTour.colorTxt'),
          title: t('tourGuide.createTour.colorTitle'),
          id: 'tg-createTemplate-color',
        },
        {
          content: t('tourGuide.createTour.startDateTxt'),
          title: t('tourGuide.createTour.startDateTitle'),
          id: 'tg-createTemplate-startDate',
        },
        {
          content: t('tourGuide.createTour.endDateTxt'),
          title: t('tourGuide.createTour.endDateTitle'),
          id: 'tg-createTemplate-endDate',
        },
        {
          content: t('tourGuide.createTour.virtualTxt'),
          title: t('tourGuide.createTour.virtualTitle'),
          id: 'tg-createTemplate-virtual',
        },
        {
          content: t('tourGuide.createTour.locationTxt'),
          title: t('tourGuide.createTour.locationTitle'),
          id: 'tg-createTemplate-location',
        },
        {
          content: t('tourGuide.createTour.quantityTxt'),
          title: t('tourGuide.createTour.quantityTitle'),
          id: 'tg-createTemplate-quantity',
        },
        {
          content: t('tourGuide.createTour.ticketOptionsTxt'),
          title: t('tourGuide.createTour.ticketOptionsTitle'),
          id: 'tg-createTemplate-ticketOptions',
        },
        {
          content: t('tourGuide.createTour.draftTxt'),
          title: t('tourGuide.createTour.publicTitle'),
          id: 'tg-createTemplate-public',
        },
      ]);
    }

    function finishTour() {
      cookies.set('tourGuideCreate', 1, -1);
    }

    watch(
      [
        templateLocation,
        templateEndDate,
        templateStartDate,
        templateLocationVirtual,
      ],
      () => {
        if (templateLocation.value) {
          const timezone = tzlookup(
            templateLocation.value.latitude,
            templateLocation.value.longitude
          );
          locationTimezoneOffset.value = moment()
            .utcOffset(moment.tz(timezone).utcOffset())
            .format('Z');
        }
        if (templateStartDate.value) {
          const templateStartLocalDateISOString = utils.getLocalDateISOString(
            new Date(templateStartDate.value).toISOString()
          );
          templateStartDateFormatted.value = templateLocationVirtual.value
            ? new Date(templateStartDate.value).toISOString()
            : templateStartLocalDateISOString.replace('Z', '') +
              locationTimezoneOffset.value;
        }
        if (templateEndDate.value) {
          const templateEndLocalDateISOString = utils.getLocalDateISOString(
            new Date(templateEndDate.value).toISOString()
          );
          templateEndDateFormatted.value = templateLocationVirtual.value
            ? new Date(templateEndDate.value).toISOString()
            : templateEndLocalDateISOString.replace('Z', '') +
              locationTimezoneOffset.value;
        }
      }
    );

    function formatDateWithLocation(date) {
      let locationTzOffset = '+00:00';
      if (templateLocation.value) {
        const timezone = tzlookup(
          templateLocation.value.latitude,
          templateLocation.value.longitude
        );
        locationTzOffset = moment()
          .utcOffset(moment.tz(timezone).utcOffset())
          .format('Z');
      }

      const dateISOString = utils.getLocalDateISOString(
        new Date(date).toISOString()
      );
      return (
        templateLocationVirtual.value
          ? new Date(date).toISOString()
          : dateISOString.replace('Z', '') + locationTzOffset
      ).replace(/T(\d{2}:\d{2}).*/, ' $1');
    }

    // If template update, fill fields with values
    if (props.template) {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Update',
          'Init',
        ]);

      templateId.value = props.template.data.id;
      templateDescription.value = props.template.data.description;

      utils.getBase64FromImageUrl(props.template.data.image).then((data) => {
        templateImagePreview.value = data;
        templateImageFile.value = utils.base64ToFile(data);
      });

      if (
        ['Basic', 'Community', 'Participation', 'Ticket', 'Role'].includes(
          props.type
        )
      ) {
        templateName.value = props.template.data.name;
        templateCriteria.value = props.template.data.criteria;
      }
      if (['Basic', 'Role'].includes(props.type)) {
        if (props.template.data.expirationDate) {
          templateValidityChoice.value = 'expirationDate';
          templateValidity.value = props.template.data.expirationDate;
        } else if (props.template.data.expirationDuration) {
          templateValidityChoice.value = 'expirationDuration';
          templateValidity.value =
            props.template.data.expirationDuration / (30 * 24 * 60 * 60);
        } else {
          templateValidityChoice.value = 'infinite';
          templateValidity.value = t('templateCreation.infiniteTxt');
        }
      }
      if (['Basic'].includes(props.type)) {
        templateTimestamped.value = props.template.data.timestamped;
      }
      if (['Role'].includes(props.type)) {
        templateBgColor.value = props.template.data.hexColorRef;
      }
      if (['Participation', 'Ticket'].includes(props.type)) {
        if (props.template.data.eventDetails.location.length > 0) {
          getCityFromDetails(
            props.template.data.eventDetails.location.split(', ')[1],
            props.template.data.eventDetails.location.split(', ')[0]
          ).then((city) => {
            templateLocation.value = city;
            locationCountry.value = countries.value.find(
              (country) => country.iso2 == city.country_code
            );
            templateLocationVirtual.value = false;
          });
        } else {
          templateLocationVirtual.value = true;
        }

        templateStartDate.value = startDatePickerValue.value = new Date(
          props.template.data.eventDetails.startDate.split('.')[0]
        ).toISOString();

        templateEndDate.value = endDatePickerValue.value = new Date(
          props.template.data.eventDetails.endDate.split('.')[0]
        ).toISOString();
      }
      if (['Participation'].includes(props.type)) {
        templateQuantity.value = props.template.data.eventDetails.limit;
      }
      if (
        ['Basic', 'Participation', 'Ticket', 'Role'].includes(props.type) &&
        props.template.data.points
      ) {
        templatePoints.value = Math.abs(props.template.data.points);
        templatePointNegative.value = props.template.data.points < 0;
      }
    } else {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Create',
          'Init',
        ]);
    }

    const templateValidityFormatted = computed(() => {
      if (!templateValidity.value) return null;

      switch (templateValidityChoice.value) {
        case 'infinite':
          return '∞ ' + t('templateCreation.infiniteTxt');
        case 'expirationDate':
          return (
            t('templateCreation.expiresOnTxt') +
            ' ' +
            utils.formatSimpleDate(templateValidity.value)
          );
        case 'expirationDuration':
          return (
            t('templateCreation.validDurationTxt') +
            ' ' +
            templateValidity.value +
            ' ' +
            (templateValidity.value > 1
              ? t('templateCreation.monthsTxt')
              : t('templateCreation.monthTxt'))
          );
      }

      return utils.formatSimpleDate(templateValidity.value);
    });

    onMounted(() => {
      const divs = document.querySelectorAll('.color-type');

      divs.forEach((div) => {
        const span = div.querySelector('.name');
        if (span && span.textContent === 'RGBA') {
          div.style.display = 'none';
        }
      });
    });

    watch([locationCountry, locationQuery], async () => {
      if (
        !locationCountry.value ||
        !locationQuery.value ||
        locationQuery.value.length < 3
      ) {
        citiesAutoComplete.value = null;
        return;
      }

      const cities = await getCitiesFromQuery(
        locationCountry.value.iso2,
        locationQuery.value
      );
      citiesAutoComplete.value = cities;
    });

    function checkInput() {
      if (
        ['Basic', 'Community', 'Participation', 'Ticket', 'Membership'].indexOf(
          props.type
        ) != -1
      ) {
        if (!templateImageFile.value)
          throw t('templateCreation.errors.pictureMissing');
      }
      if (
        ['Basic', 'Community', 'Participation', 'Ticket', 'Role'].indexOf(
          props.type
        ) != -1
      ) {
        if (!templateName.value)
          throw t('templateCreation.errors.titleMissing');
        if (templateName.value.length > 100)
          throw t('templateCreation.errors.titleTooLong');
      }
      if (
        [
          'Basic',
          'Community',
          'Participation',
          'Ticket',
          'Membership',
          'Role',
        ].indexOf(props.type) != -1
      ) {
        if (!templateDescription.value)
          throw t('templateCreation.errors.descriptionMissing');
        if (templateDescription.value.length > 2000)
          throw t('templateCreation.errors.descriptionTooLong');
      }
      // if (
      //   ['Basic', 'Community', 'Participation', 'Ticket', 'Role'].indexOf(
      //     props.type
      //   ) != -1
      // ) {
      //   if (!templateCriteria.value)
      //     throw t('templateCreation.errors.criteriaMissing');
      //   if (templateCriteria.value.length > 2000)
      //     throw t('templateCreation.errors.criteriaTooLong');
      // }
      if (['Basic', 'Role'].indexOf(props.type) != -1) {
        if (!templateValidity.value)
          throw t('templateCreation.errors.validityMissing');
      }
      if (['Participation', 'Ticket'].indexOf(props.type) != -1) {
        if (!templateStartDate.value)
          throw t('templateCreation.errors.startDateMissing');
        if (!templateEndDate.value)
          throw t('templateCreation.errors.endDateMissing');
        if (!templateLocationVirtual.value && !templateLocation.value)
          throw t('templateCreation.errors.locationMissing');
        if (
          templateQuantity.value &&
          Number(templateQuantity.value) > 10000000000
        )
          throw t('templateCreation.errors.quantityNotValid');
      }
      if (
        ['Basic', 'Participation', 'Ticket', 'Role'].indexOf(props.type) != -1
      ) {
        if (
          templatePoints.value &&
          (!(
            !isNaN(parseFloat(templatePoints.value)) &&
            isFinite(templatePoints.value)
          ) ||
            Math.abs(Number(templatePoints.value) > 10000000000))
        )
          throw t('templateCreation.errors.pointsNotValid');
      }

      return true;
    }

    function checkEmailInput() {
      if (!emailList.value.length) throw t('signup.errors.emailMissing');
      return true;
    }

    async function getCitiesFromQuery(countryCode, query) {
      return (await api.getCitiesFromQuery(countryCode, query)).data;
    }

    async function getCityFromDetails(country, name) {
      return (await api.getCityFromDetails(country, name)).data;
    }

    function getUpdatedData() {
      const data = {
        lang: templateLang.value,
        description: templateDescription.value,
        criteria: templateCriteria.value,
      };

      if (
        ['Basic', 'Community', 'Participation', 'Ticket', 'Role'].includes(
          props.type
        )
      ) {
        data.name = templateName.value;
      }

      if (
        ['Basic', 'Community', 'Participation', 'Ticket'].includes(props.type)
      ) {
        data.themeBgColor = templateBgColor.value;
        data.themeTextColor = templateTextColor.value;
        data.themeCardEffect = templateEffect.value;
        data.themeTextureImageURL = templateTexture.value;
        data.themeIsLogoBack = templateIsLogoBack.value;
      }

      if (['Role'].includes(props.type)) {
        data.themeBgColor = templateBgColor.value;
        data.themeShape = templateShape.value;
      }

      if (['Basic'].includes(props.type)) {
        data.timestamped = templateTimestamped.value;
      }

      if (['Basic', 'Role'].includes(props.type)) {
        data.expirationDate =
          templateValidityChoice.value == 'expirationDate'
            ? new Date(templateValidity.value).toISOString()
            : null;
        data.expirationDuration =
          templateValidityChoice.value == 'expirationDuration'
            ? new Date(templateValidity.value).toISOString()
            : null;
      }

      if (['Basic', 'Participation', 'Ticket', 'Role'].includes(props.type)) {
        data.points =
          !templatePoints.value || templatePoints.value == ''
            ? null
            : templatePoints.value * (templatePointNegative.value ? -1 : 1);
      }

      if (['Role'].includes(props.type)) {
        data.hexColorRef = templateBgColor.value;
      }

      if (['Participation', 'Ticket'].includes(props.type)) {
        data.location = templateLocationVirtual.value
          ? ''
          : `${templateLocation?.value?.name}, ${templateLocation?.value?.country_name}`;
        data.startDate = templateStartDateFormatted.value;
        data.endDate = templateEndDateFormatted.value;
        data.limit =
          !templateQuantity.value || templateQuantity.value == ''
            ? null
            : templateQuantity.value;
        if (['Ticket'].includes(props.type)) {
          data.optionName = templateOptionName.value;
          data.optionSeat = templateOptionSeat.value;
          data.optionSection = templateOptionSection.value;
        }
      }

      return data;
    }

    async function addIssuerTemplate() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Create',
          'Confirm',
        ]);
      try {
        checkInput();
      } catch (err) {
        utils.showToastMessageError(err);
        return;
      }

      if (!templateDraftMode.value) {
        waitingSignature.value = 'signatureAdd';
      }

      api
        .createTemplate(
          {
            draft: templateDraftMode.value,
            ...getUpdatedData(),
          },
          templateImageFile.value,
          props.type
        )
        .then((res) => {
          if (window._paq)
            window._paq.push([
              'trackEvent',
              `Template ${props.type}`,
              'Create ' + (templateDraftMode.value ? '' : ' Draft'),
              'Success',
            ]);

          if (templateDraftMode.value) {
            context.emit('refreshTemplateList');
            context.emit('close');
            return;
          }

          const { callbackId, templateId } = res.data;
          newTemplateId.value = templateId;
          console.log('calling waitOperationValidation from template create');
          api.waitOperationValidation(callbackId, (event) => {
            if (event.status == 'canceled') {
              waitingSignature.value = null;
            } else if (event.status == 'pending') {
              waitingSignature.value = null;
              context.emit('refreshTemplateList');
              context.emit('close');
            } else if (event.status == 'error') {
              console.log('receive error status !');
              waitingSignature.value = null;
              utils.showToastMessageError(t('other.serverError'));
            }
          });
        })
        .catch((err) => {
          console.log('err', err);
          if (err.response?.data?.code == 2000) {
            utils.showToastMessageError(
              t('templateCreation.errors.imageAspectRatioNotValid')
            );
          } else if (err.response?.data?.message) {
            utils.showToastMessageError(err.response?.data?.message);
          } else {
            utils.showToastMessageError(t('other.serverError'));
          }
          waitingSignature.value = null;
        });
    }

    async function updateIssuerTemplate() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Update',
          'Confirm',
        ]);

      try {
        checkInput();
      } catch (err) {
        utils.showToastMessageError(err);
        return;
      }

      if (props.template.contractState != 'none') {
        waitingSignature.value = 'signatureEdit';
      }

      api
        .updateTemplate(
          {
            id: props.template.id,
            ...getUpdatedData(),
          },
          templateImageFile.value,
          props.type
        )
        .then((res) => {
          if (window._paq)
            window._paq.push([
              'trackEvent',
              `Template ${props.type}`,
              'Update ' + (templateDraftMode.value ? '' : ' Draft'),
              'Confirm',
            ]);

          if (props.template.contractState == 'none') {
            context.emit('refreshTemplate', props.template.id);
            context.emit('close');
          }

          const { callbackId } = res.data;
          console.log('calling waitOperationValidation from template update');
          api.waitOperationValidation(callbackId, (event) => {
            if (event.status == 'canceled') {
              waitingSignature.value = null;
            } else if (event.status == 'pending') {
              waitingSignature.value = null;
              context.emit('refreshTemplate', props.template.id);
              context.emit('close');
            } else if (event.status == 'error') {
              console.log('receive error status !');
              waitingSignature.value = null;
              utils.showToastMessageError(t('other.serverError'));
            }
          });
        })
        .catch((err) => {
          if (err.response?.data?.code == 2000) {
            utils.showToastMessageError(
              t('templateCreation.errors.imageAspectRatioNotValid')
            );
          } else {
            utils.showToastMessageError(t('other.serverError'));
          }
          waitingSignature.value = null;
        });
    }

    async function sendOpenLink() {
      if (window._paq)
        window._paq.push([
          'trackEvent',
          `Template ${props.type}`,
          'Send open link onboarding',
          'Confirm',
        ]);

      if (!newTemplateId.value) return;

      try {
        checkEmailInput();
      } catch (err) {
        utils.showToastMessageError(err);
        return;
      }

      creationSuccessful.value = false;

      sendingInProgress.value = true;
      api
        .sendOpenSessionByEmail({
          emails: emailList.value,
          templateId: newTemplateId.value,
        })
        .then(() => {
          if (window._paq)
            window._paq.push([
              'trackEvent',
              `Template ${props.type}`,
              'Send open link onboarding',
              'Success',
            ]);
          sendingInProgress.value = false;
          sendingSuccessful.value = true;
        })
        .catch((err) => {
          if (err.response?.data?.code != 1000) {
            utils.showToastMessageError(t('other.serverError'));
          }
          waitingSignature.value = null;
        });
    }

    function deleteIssuerPictureInput() {
      templateImageFile.value = null;
      templateImageMessage.value = null;
      templateImagePreview.value = null;
    }

    function triggerCloseEvent(cancel) {
      if (cancel && props.template) context.emit('close', props.template.hash);
      else context.emit('close', newTemplateHash.value);
    }

    function treatEmailInput() {
      if (!emailInput.value) return;
      const potentialEmails = emailInput.value.split(/[\s,;]+/);
      const validEmails = potentialEmails.filter((email) =>
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)
      );

      emailList.value = [...new Set([...emailList.value, ...validEmails])]; // new Set() removes duplicate
      emailInput.value = null;
    }

    function removeFromEmailList(index) {
      emailList.value.splice(index, 1);
    }

    async function copyToClipboard() {
      await navigator.clipboard.writeText(qrCodeLink.value);
      utils.showToastMessageSuccess(t('other.clipboardCopyTxt'));
    }

    return {
      currentIssuerInfo,
      triggerCloseEvent,
      templateLabel,
      templateName,
      templateDescription,
      templateLocation,
      templateLocationVirtual,
      templateCriteria,
      templateTimestamped,
      templateDraftMode,
      templateQuantity,
      templatePoints,
      templatePointNegative,
      templateOptionName,
      templateOptionSeat,
      templateOptionSection,
      templateValidity,
      templateValidityChoice,
      templateValidityFormatted,
      templateBgColor,
      templateImageFile,
      templateImagePreview,
      templateImageMessage,
      deleteIssuerPictureInput,
      showEmojiPicker,
      emojiStock,
      emojiStockIndex,
      showColorPicker,
      showEditPictureButton,
      showValidityMenu,
      showValidityMenuDate,
      showValidityMenuDuration,
      durationMonthValue,
      startDatePickerValue,
      endDatePickerValue,
      showStartDatePicker,
      showEndDatePicker,
      templateStartDate,
      templateStartDateFormatted,
      templateEndDate,
      templateEndDateFormatted,
      waitingSignature,
      creationSuccessful,
      creationSuccessfulCompleted,
      creationSuccessfulSkip,
      updateSuccessful,
      qrCodeLink,
      addIssuerTemplate,
      updateIssuerTemplate,
      locationQuery,
      locationCountry,
      citiesAutoComplete,
      countries,
      showCountryPicker,
      showImageHandler,
      newTemplateHash,
      newTemplateId,
      emailList,
      emailListScrollDiv,
      emailInput,
      treatEmailInput,
      removeFromEmailList,
      copyToClipboard,
      sendOpenLink,
      sendingSuccessful,
      sendingInProgress,
      startTour,
      tourGuideRef,
      finishTour,
      formatDateWithLocation,
      utils,
      pointAuthorized,
      templateTextColor,
      showTextColorPicker,
      templateIsLogoBack,
      templateShape,
      showTextureSelector,
      templateTexture,
      templateEffect,
      showAdvanced,
      templatePreviewRef,
    };
  },
  components: {
    TemplatePreview,
    ToggleButton,
    BasicSmModal,
    ColorPicker,
    ImageHandler,
    TourGuide,
    TextureSelector,
  },
};
</script>
